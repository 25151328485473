import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { FormattedMessage, injectIntl } from "react-intl";
import Icon from "../Icon";
import CustomLabel from "../CustomLabel";

interface ModalManagerProps {
  showModal: boolean;
  closeModal: () => void;
  title: string;
  content: string;
  modalType:
    | "error"
    | "success"
    | "confirmation"
    | "notification"
    | "prompt"
    | "loading"
    | "processing";
  buttonLabel?: string;
  successCallback?: () => Promise<void>;
  onCancel?: () => void;
  hideCancelButton?: boolean;
  intl: any;
  mandatoryConfirmation?: boolean;
  inputConfirmationData: () => void;
  abortCallback?: () => void;
  cancelButtonLabel?: JSX.Element | string;
  modalButtonConfirm?: JSX.Element | string;
  context?: string;
  promptSuccessLabel?: string;
  promptCancelLabel?: string;
  size?: 'sm' | 'lg' | 'xl';
  promptLabel?: string;
  event: () => void;
  percentage: number;
  message: string;
  variant: any;
}

const ModalManager: React.FC<ModalManagerProps> = ({
  showModal,
  closeModal,
  title,
  content,
  modalType,
  buttonLabel,
  onCancel,
  successCallback,
  hideCancelButton,
  intl,
  mandatoryConfirmation,
  modalButtonConfirm,
  context = "info",
  promptSuccessLabel,
  promptCancelLabel,
  promptLabel,
  size,
  event,
  percentage,
  message,
  variant,
}) => {
  const [disabled] = useState(false);
  const [inputConfirmationData, setInputConfirmationData] = useState("");
  const [generatedCode, setGeneratedCode] = useState<string | null>(null);

  //*UseEffect for confirmation Modal
  useEffect(() => {
    if (mandatoryConfirmation) {
      // Generate a random 6-digit code when mandatory confirmation is required
      const code = Math.floor(Math.random() * 900000 + 100000).toString();
      setGeneratedCode(code);
    } else {
      setGeneratedCode(null); // Reset the code if not required
    }
  }, [mandatoryConfirmation]);

  //*useEffect for prompt Modal

  useEffect(() => {
    if (typeof event === "function") {
      event();
    }
  }, [event]);

  const handleClose = () => {
    closeModal();
  };

  const handleConfirmation = async () => {
    try {
      if (successCallback) await successCallback();
      handleClose();
    } catch (error) {
      console.error("Error in handleConfirmation:", error);
    }
  };

  const handleCancel = async (ev: React.MouseEvent<HTMLButtonElement>) => {
    if (onCancel) await onCancel();
    handleClose();
  };

  const confirm = () => {
    successCallback?.();
    closeModal?.();
  };

  const verifyRequiredInput = (data: string) => {
    setInputConfirmationData(data);
  };

  const renderModalHeader = () => {
    if (modalType === "notification") {
      return (
        <div>
          <Icon icon="info-circle" size="2xl" className="mr-3" />
          {title}
        </div>
      );
    } else {
      return (
        <Modal.Title
          className={
            context !== "info" ? `modal-title text-${context}` : "modal-title"
          }
        >
          {title}
        </Modal.Title>
      );
    }
  };

  const ModalSize =
    modalType === "processing"
      ? "xl"
      : modalType === "error"
      ? "xl"
      : undefined;

  const renderModalBody = () => {
    switch (modalType) {
      case "prompt":
        return (
          <Modal.Body>
            <CustomLabel
              label={promptLabel || intl.formatMessage({ id: "Comment" })}
              htmlFor="comment"
            />

            <div>
              <textarea
                className="form-control"
                id="comment"
                name="comment"
                rows={4}
                maxLength={500}
              />
            </div>
          </Modal.Body>
        );
      case "loading":
        return (
          <Modal.Body>
            <p className="alert alert-warning">{content}</p>
            <div className="text-center m-5">
              <div>
                <Icon icon="fa-spinner" className="fa-spin-pulse" size="2xl" />
              </div>
            </div>
          </Modal.Body>
        );
      case "confirmation":
        return (
          <Modal.Body>
            {content}

            {mandatoryConfirmation && generatedCode && (
              <p className="text-danger mt-2">
                <strong>
                  <FormattedMessage
                    id="Mandatory.Confirmation.Message"
                    values={{ code: generatedCode }}
                  />
                </strong>
                <br />
                <input
                  type="text"
                  className="form-control border-danger mt-1"
                  placeholder={intl.formatMessage({ id: "Code" })}
                  value={inputConfirmationData}
                  onChange={(e) => verifyRequiredInput(e.target.value)}
                />
              </p>
            )}
          </Modal.Body>
        );
      case "processing":
        return (
          <Modal.Body>
            <div className="p-5">
              <div className="text-center">
                <h4>{message}</h4>
              </div>
              <div className="progress">
                <div
                  id="processingBar"
                  className={
                    "progress-bar progress-bar-striped progress-bar-animated bg-" +
                    variant
                  }
                  role="progressbar"
                  style={{ width: percentage + "%" }}
                  aria-valuenow={percentage}
                  aria-valuemin={0}
                  aria-valuemax={100}
                ></div>
              </div>
            </div>
          </Modal.Body>
        );
      default:
        return <Modal.Body>{content}</Modal.Body>;
    }
  };
  const renderFooter = () => {
    switch (modalType) {
      case "confirmation":
        return (
          <Modal.Footer>
            {!hideCancelButton && (
              <Button variant="secondary" onClick={handleCancel}>
                {buttonLabel ? buttonLabel : <FormattedMessage id="Cancel" />}
              </Button>
            )}
            <Button
              variant={context}
              onClick={handleConfirmation}
              disabled={
                mandatoryConfirmation
                  ? inputConfirmationData !== generatedCode || disabled
                  : disabled
              }
            >
              {buttonLabel ? buttonLabel : <FormattedMessage id="Confirm" />}
              {modalButtonConfirm}
            </Button>
          </Modal.Footer>
        );

      case "error":
        return (
          <Modal.Footer>
            <Button variant="danger" onClick={handleClose}>
              {buttonLabel ? buttonLabel : <FormattedMessage id="Ok" />}
            </Button>
          </Modal.Footer>
        );

      case "success":
        return (
          <Modal.Footer>
            <Button variant="info" onClick={handleClose}>
              <FormattedMessage id="Ok" />
            </Button>
          </Modal.Footer>
        );

      case "notification":
        return (
          <Modal.Footer>
            <Button variant="warning" onClick={confirm}>
              <FormattedMessage id="Ok" />
            </Button>
          </Modal.Footer>
        );

      case "prompt":
        return (
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={handleClose}
              disabled={disabled}
            >
              {promptCancelLabel || <FormattedMessage id="No" />}
            </Button>
            <Button
              variant="info"
              onClick={handleConfirmation}
              disabled={disabled}
            >
              {promptSuccessLabel || <FormattedMessage id="Yes" />}
            </Button>
          </Modal.Footer>
        );

      default:
        return null;
    }
  };

  return (
    <Modal
      show={showModal}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      size={ModalSize}
      animation={false}
      centered={modalType === "processing" ? true : false}
    >
      {modalType !== "processing" && <Modal.Header closeButton>{renderModalHeader()}</Modal.Header>}
      {renderModalBody()}
      {renderFooter()}
    </Modal>
  );
};

export default injectIntl(ModalManager);
