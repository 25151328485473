import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { deleteResident } from "../../actions/residents/residents";
import Paginator from "../sub/Paginator";
import Util from "../../util/Util";
import EquipmentRow from "../fleet/EquipmentRow";
import EquipmentRowAdmin from "../fleet/admin/EquipmentRowAdmin";
import EquipmentModal from "../fleet/EquipmentModal";
import EquipmentModalAdmin from "../fleet/admin/EquipmentModalAdmin";
import ResidentRow from "./ResidentRow";
import APIUrl from "../../APIUrl";
import Icon from "../sub/Icon";
import TableToolbar from "../sub/bootstrap/TableToolbar";
import MenuButton from "../sub/bootstrap/MenuButton";

class Residents extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: null,
      nameFilter: "",
    };

    this.paginator = new Paginator(this);
  }

  closeModal() {
    this.setState({ modal: null });
  }

  resetSearchFields() {
    this.setState({
      nameFilter: "",
    });
  }

  areResultsFiltered = () => {
    if (!Util.emptyString(this.state.nameFilter)) {
      return true;
    } else {
      return false;
    }
  };

  onChange(field, value) {
    this.setState({ [field]: value });
  }

  editEquipment(equipment) {
    let materiovigilance = (
      <h6>
        <span className={"w-100 badge badge-light"}>-</span>
      </h6>
    );
    let materiovigilanceItem = this.props.materiovigilance.filter(
      (materiovigilance) => materiovigilance.model_id === equipment.model_id
    )[0];
    if (materiovigilanceItem !== undefined) {
      var mvClassName = "";
      if (parseInt(materiovigilanceItem.materiovigilance) === 1)
        mvClassName = "danger";
      else if (parseInt(materiovigilanceItem.materiovigilance) === 2)
        mvClassName = "info";
      else if (parseInt(materiovigilanceItem.materiovigilance) === 3)
        mvClassName = "warning";

      materiovigilance = (
        <div className={"form-control w-100 alert-" + mvClassName}>
          <h6 className="space-between">
            {this.props.intl.formatMessage({
              id: "EqMateriovigilance." + materiovigilanceItem.materiovigilance,
            })}
            {materiovigilanceItem.doc_url !== undefined &&
              materiovigilanceItem.doc_url !== null && (
                <a
                  role="button"
                  href={
                    APIUrl.getMateriovigilanceFile +
                    materiovigilanceItem._id +
                    "/" +
                    materiovigilanceItem.doc_url.split("/")[
                    materiovigilanceItem.doc_url.split("/").length - 1
                    ] +
                    "?token=" +
                    APIUrl.jwtToken
                  }
                  rel="noopener noreferrer"
                  download={
                    materiovigilanceItem +
                    "." +
                    materiovigilanceItem.doc_url.split("/")[
                    materiovigilanceItem.doc_url.split("/").length - 1
                    ]
                  }
                  target={"_blank"}
                >
                  <Icon
                    className={"icon-small alert-" + mvClassName}
                    icon="download"
                  />
                </a>
              )}
          </h6>
        </div>
      );
    } else {
      materiovigilance = (
        <div className={"form-control w-100 alert-success"}>
          <h6>
            {this.props.intl.formatMessage({ id: "EqMateriovigilance.0" })}
          </h6>
        </div>
      );
    }
    if (this.props.user.role === 0) {
      this.setState({
        modal: (
          <EquipmentModal
            close={() => this.closeModal()}
            establishmentId={this.props.establishmentId}
            equipment={equipment}
            materiovigilance={materiovigilance}
          />
        ),
      });
    } else if (this.props.user.role === 1 || this.props.user.role === 2) {
      this.setState({
        modal: (
          <EquipmentModalAdmin
            close={() => this.closeModal()}
            establishmentId={this.props.establishmentId}
            // equipment={equipment}
            materiovigilance={materiovigilance}
            equipmentId={equipment._id}
          />
        ),
      });
    }
  }

  updateChildState(data) {
    this.setState(data);
  }

  render() {

    this.paginator.init();
    let disableFormInput = this.paginator.paginationIndex !== 1 ? true : false;

    let residentsNode = this.props.residents.map((resident) => {
      var equipementsAffectedArray = this.props.equipments.filter(
        (equipment) => equipment.resident_id === resident._id
      );

      var equipementAffected = "";
      if (equipementsAffectedArray.length !== 0) {
        equipementAffected = equipementsAffectedArray.map((equipment) => {
          var maintenances = [];
          for (var maintenance of this.props.maintenances) {
            if (maintenance.equipment_id === equipment._id) {
              maintenances.push(maintenance);
            }
          }
          var materiovigilance = undefined;

          for (var materiovigilances of this.props.materiovigilance) {
            if (materiovigilances.model_id === equipment.model_id) {
              materiovigilance = materiovigilances.materiovigilance;
            }
          }

          if (parseInt(this.props.user.role) === 0) {
            return (
              <EquipmentRow
                key={equipment._id}
                materiovigilance={materiovigilance}
                equipment={equipment}
                maintenances={maintenances}
                editEquipment={(equipment) => this.editEquipment(equipment)}
                mode={"quickview"}
              />
            );
          } else if (
            parseInt(this.props.user.role) === 1 ||
            parseInt(this.props.user.role) === 2
          ) {
            return (
              <EquipmentRowAdmin
                key={equipment._id}
                materiovigilance={materiovigilance}
                equipment={equipment}
                maintenances={maintenances}
                requests={[]}
                editEquipment={(equipment) => this.editEquipment(equipment)}
                mode={"quickview"}
              />
            );
          } else {
            return null;
          }
        });
      }

      if (this.state.nameFilter && this.state.nameFilter !== "") {
        if (
          resident.name
            .toUpperCase()
            .indexOf(this.state.nameFilter.toUpperCase()) === -1 &&
          resident.first_name
            .toUpperCase()
            .indexOf(this.state.nameFilter.toUpperCase()) === -1
        )
          return null;
      }

      // Let this instruction right before render's retrun

      if (!this.paginator.keep()) return null;

      return (
        <ResidentRow
          key={resident._id}
          resident={resident}
          equipment={equipementAffected}
          establishmentId={this.props.establishmentId}
          updateParentState={this.updateChildState.bind(this)}
          closeModal={() => this.closeModal()}
        />
      );
    });

    return (
      <React.Fragment>
        {this.props.residents && (
          <div>
            <div className="alert alert-info">
              <FormattedMessage
                id="Resident.Count"
                values={{ count: this.props.residents.length }}
              />
            </div>
            <TableToolbar>
              <input
                id="search_name"
                className="form-control"
                onChange={(e) =>
                  this.onChange("nameFilter", e.target.value)
                }
                placeholder={
                  this.props.intl.formatMessage({
                    id: "Name",
                  }) +
                  " " +
                  this.props.intl.formatMessage({
                    id: "Or",
                  }) +
                  " " +
                  this.props.intl.formatMessage({
                    id: "First.Name",
                  })
                }
                //   disabled={disableFormInput}
                value={this.state.nameFilter}
                disabled={disableFormInput}
              />
              <MenuButton
                onClick={() => this.resetSearchFields()}
                hover={(this.areResultsFiltered() && !disableFormInput) && <FormattedMessage id="Remove.Filter" />}
                variant={this.areResultsFiltered() ? "warning" : "outline-secondary"}
                icon="filter"
                disabled={!this.areResultsFiltered() || disableFormInput}
              />
              <MenuButton
                variant="info"
                className="ml-auto"
                icon="person-circle-plus"
                onClick={(e) => this.props.openResidentModal()}
              >
                <FormattedMessage id="Resident.Add" />
              </MenuButton>
            </TableToolbar>
          </div>
        )}
        <table className="table tablee4coll">
          <thead>
            <tr className="d-flex">
              <th className="col-9">
                <FormattedMessage id="Name" /> <FormattedMessage id="And" />{" "}
                <FormattedMessage id="First.Name" />
              </th>
              <th className="col-2 text-center">
                <FormattedMessage id="Associated.Equipments" />
              </th>
              <th className="col-1 text-center">
                <FormattedMessage id="Actions" />
              </th>
            </tr>
          </thead>

          <tbody>{residentsNode}</tbody>
        </table>

        {this.paginator.render()}

        {this.state.modal}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    // residents: state.residents,
    equipments: state.equipments,
    brands: state.brands,
    models: state.models,
    materiovigilance: state.materiovigilance,
    maintenances: state.maintenances,
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onDeleteResident: (residentId, successCallback) =>
      dispatch(deleteResident(residentId, successCallback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(Residents));
