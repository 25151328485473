import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import APIUrl from "../../APIUrl";
import AutoSuggestProducts from "../sub/AutoSuggestProducts";
import ProductsParserUtil from "../../util/ProductsParserUtil";
import Util from "../../util/Util";
import { Link } from "react-router-dom";
import MercurialStatus from "../../enums/MercurialStatus";
import {
  getProductsByMercurialIdAdmin,
  getProductsByEstablishmentIdAdmin,
} from "../../actions/products/products";
import ReactImageFallback from "react-image-fallback";
import "../../css/families/families.css";
import TableToolbar from "../sub/bootstrap/TableToolbar";
import UploadCustomImgModal from "../sub/modals/UploadCustomImgModal";
import { NotificationManager } from "react-notifications";
import { removeFamilyImgs, addFamilyImgs } from "../../actions/mercurials/mercurials";
import MenuButton from "../sub/bootstrap/MenuButton";

class FamiliesAdmin extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      establishmentId: "",
      mercurialId: this.getMercurialId(),
      modal: null,
      isCustom: false,
      replaceImgBtn: [],
      countImg: 0,
      imageFamilyRender: null,
    };
  }

  getMercurialId() {
    let id = this.props.match.params.mercurialId;
    return id;
  }

  checkProductsExistByMercurialId(mercurialId) {
    for (let i = 0; i < this.props.products.length; i++) {
      if (this.props.products[i].mercurial_id === mercurialId) {
        return false;
      }
    }
    return true;
  }

  componentDidMount() {

    new Promise(
      (res, rej) => this.imageChangeRender(res, rej, this)).then(
        (value) => this.setState({
          imageFamilyRender: value
        }
        )
      );

    switch (true) {
      case this.state.mercurialId !== "" &&
        this.state.mercurialId !== undefined &&
        this.props.products &&
        this.props.products.length !==
        this.getMercurial(this.state.mercurialId).productsLength:
      case this.props.products &&
        this.state.mercurialId &&
        this.state.mercurialId !== undefined &&
        this.props.products.length !==
        this.getMercurial(this.state.mercurialId).productsLength:
        this.props.onGetProductsByMercurialIdAdmin(this.state.mercurialId);
        break;

      case this.state.establishmentId !== "" &&
        this.props.products &&
        this.props.products.length !==
        this.getMercurial(
          this.getMercurialIdByEstablishmentId(this.state.establishmentId)
        ).productsLength:
        this.props.onGetProductsByEstablishmentIdAdmin(
          this.state.establishmentId
        );
        break;

      default:
        break;
    }
  }

  componentDidUpdate(prevProps, prevState) {
    switch (true) {
      case this.state.mercurialId !== prevState.mercurialId &&
        this.state.mercurialId !== "" &&
        this.state.mercurialId !== undefined &&
        this.props.products &&
        this.getProductsLengthByMercurialId(this.state.mercurialId) !==
        this.getMercurial(this.state.mercurialId).productsLength:
      case this.props.products &&
        this.props.products.length === 0 &&
        this.state.mercurialId &&
        this.state.mercurialId !== undefined &&
        this.getProductsLengthByMercurialId(this.state.mercurialId) !==
        this.getMercurial(this.state.mercurialId).productsLength:
        this.props.onGetProductsByMercurialIdAdmin(this.state.mercurialId);
        break;

      case this.state.establishmentId !== prevState.establishmentId &&
        this.state.establishmentId !== "" &&
        this.props.products &&
        this.getProductsLengthByMercurialId(
          this.getMercurialIdByEstablishmentId(this.state.establishmentId)
        ) !==
        this.getMercurial(
          this.getMercurialIdByEstablishmentId(this.state.establishmentId)
        ).productsLength:
        this.props.onGetProductsByEstablishmentIdAdmin(
          this.state.establishmentId
        );
        break;
      case (this.state.imageFamilyRender !== prevState.imageFamilyRender && (this.props.products &&
        this.props.products.length !== 0 && (this.state.imageFamilyRender === null || this.state.imageFamilyRender === undefined))) || this.state.mercurialId !== prevState.mercurialId:
        new Promise((res, rej) => this.imageChangeRender(res, rej, this)).then((value) => this.setState({ imageFamilyRender: value }));
        break;
      case (this.state.imageFamilyRender === prevState.imageFamilyRender &&
        this.getProductsLengthByMercurialId(this.state.mercurialId) ===
        this.getMercurial(this.state.mercurialId).productsLength) && this.state.modal === prevState.modal: //pour éviter les chargements intempestifs
        new Promise((res, rej) => this.imageChangeRender(res, rej, this)).then((value) => this.setState({ imageFamilyRender: value }));
        break;
      default:
        break;
    }
  }

  getMercurialEstablishment() {
    let mercurialId = this.getMercurialId();
    let establishments = [];
    for (let establishmentSettings of this.props.establishmentsSettings) {
      if (establishmentSettings.mercurial_id === mercurialId)
        establishments.push(establishmentSettings.establishment_id);
    }
    return establishments;
  }

  getProductsLengthByMercurialId(mercurialId) {
    let response = 0;
    for (let p of this.props.products) {
      if (p.mercurial_id === mercurialId) response = response + 1;
    }
    return response;
  }

  getMercurialIdByEstablishmentId(establishmentId) {
    let response = {};
    for (let establishmentsSetting of this.props.establishmentsSettings) {
      if (establishmentsSetting.establishment_id === establishmentId)
        response = establishmentsSetting.mercurial_id;
    }
    return response;
  }

  getMercurial(mercurialId) {
    let response = {};
    for (let mercurial of this.props.mercurials) {
      if (mercurial._id === mercurialId) response = mercurial;
    }
    return response;
  }

  getEstablishments() {
    let establishmentsId = this.getMercurialEstablishment();
    let establishments = [];
    for (let establishment of this.props.establishments) {
      for (let establishmentId of establishmentsId) {
        if (establishment._id === establishmentId)
          establishments.push(establishment);
      }
    }
    return establishments;
  }

  selectEstablishment(establishmentId) {
    this.setState({ establishmentId: establishmentId, mercurialId: "" });
  }

  selectMercurial(mercurialId) {
    this.setState({ mercurialId: mercurialId, establishmentId: "" });
  }

  getDesignation(product) {
    if (!product.designation) return product.sous_famille;

    if (product.designation.indexOf("-") === 0) return product.designation;

    return product.designation.split("-")[0];
  }

  closeModal() {
    this.setState({
      modal: null
    })
  }

  onSearch(value, products, mercurialId) {
    // If it's a product
    for (let product of products) {
      if (product.ref === value.ref && product.mercurial_id === mercurialId) {
        window.location.href =
          "#/home/products/mercurial/" +
          product.mercurial_id +
          "/" +
          encodeURI(product.famille) +
          "/" +
          product._id;
      }
    }
  }

  productsToSuggestions(products) {
    var suggestions = [];
    var alreadyUsedFamilies = {};

    for (let product of products) {
      var sugg = ProductsParserUtil.trim(product);

      if (!sugg && !alreadyUsedFamilies[product.sous_famille]) {
        sugg = product.sous_famille;
        alreadyUsedFamilies[product.sous_famille] = true;
      }

      if (sugg) suggestions.push(sugg);
    }

    return suggestions;
  }

  // In mercurial "create" mode, Step 3 is when we let the ability to the user to upload associated categories/products images
  openCustomFamilyImgUploadModal(familyFormat, currentProductImage) {
    const databaseDefaultProductImgUrl =
      APIUrl.getFamilyImgDefault +
      this.state.mercurialId +
      "/FAM_" +
      familyFormat.fam.split(".")[0] +
      "/?token=" +
      APIUrl.jwtToken;
    this.setState({
      modal: (
        <UploadCustomImgModal
          closeModal={() => this.closeModal()}
          productFormat={familyFormat}
          defaultImage={databaseDefaultProductImgUrl}
          currentImage={currentProductImage}
          textInfo={<FormattedMessage id="Imagery.Message.1" />}
          mode="upload"
          onComplete={(newProductImage) => {
            this.uploadFamilyImage(
              "FAM_" + familyFormat.fam.split(".")[0] + ".png",
              newProductImage,
              () => {
                this.closeModal();
                this.setState({ imageFamilyRender: null });
                window.location.reload();
              }
            );
          }
          }
        />
      ),
    });
  }

  // In mercurial "create" mode, Step 3 is when we let the ability to the user to upload associated categories/products images
  openCustomFamilyImgRemoveModal(
    familyFormat,
    currentProductImage
  ) {
    const databaseDefaultProductImgUrl =
      APIUrl.getFamilyImgDefault +
      this.state.mercurialId +
      "/FAM_" +
      familyFormat.fam.split(".")[0] +
      "/?token=" +
      APIUrl.jwtToken;
    this.setState({
      modal: (
        <UploadCustomImgModal
          closeModal={() => this.closeModal()}
          productFormat={familyFormat}
          currentImage={currentProductImage}
          defaultImage={databaseDefaultProductImgUrl}
          textInfo={<FormattedMessage id="Imagery.Message.2" />}
          mode="delete"
          onComplete={() =>
            this.removeFamilyImage("FAM_" + familyFormat.fam.split(".")[0], () => { this.closeModal(); this.setState({ imageFamilyRender: null }); })
          }
        />
      ),
    });
  }

  // Management of images uploading
  uploadFamilyImage(productfam, productImage, successCallbackToReload) {
    var sendFamilyImage = (successCallback) => {
      if (!productImage || productImage.length === 0) return successCallback();

      let formData = new FormData();

      // Tell the backend that we want to use a custom images even if a database image exists
      formData.append("useCustomImage", true);

      for (let img of productImage) {
        formData.append(productfam, img);
      }

      this.props.onAddFamilyImgs(
        this.state.mercurialId,
        formData,
        successCallback
      );
    };

    // Notification after image update
    let errorMessage = this.props.intl.formatMessage({
      id: "Family.Image.Updated",
    });

    sendFamilyImage(() => { successCallbackToReload(NotificationManager.success(errorMessage)); });
  }

  // Management of images deletion
  removeFamilyImage(clientFam, successCallbackToReload) {
    var removeFamilyImage = (successCallback) => {
      if (!clientFam) return successCallback();
      const data = {
        removeFam: { clientFam: clientFam },
      };
      this.props.onRemovefamilyImgs(
        this.state.mercurialId,
        data,
        successCallback
      );
    };

    // Notification after image upldate
    let errorMessage = this.props.intl.formatMessage({
      id: "Family.Image.Updated",
    });

    removeFamilyImage(() => successCallbackToReload(NotificationManager.success(errorMessage)));
  }

  async imageChangeRender(resolve, reject, that) {
    var establishmentMercurialId = null;
    if (
      that.state.establishmentId &&
      !Util.emptyString(that.state.establishmentId)
    ) {
      for (let establishmentsSetting of that.props.establishmentsSettings) {
        if (
          establishmentsSetting.establishment_id === that.state.establishmentId
        ) {
          establishmentMercurialId = establishmentsSetting.mercurial_id;
          break;
        }
      }
    }
    var familiesUniques = {};

    for (let product of that.props.products) {
      // Mercurials filter
      if (
        that.state.mercurialId &&
        !Util.emptyString(that.state.mercurialId) &&
        that.state.mercurialId !== product.mercurial_id
      )
        continue;

      // Establishment filter
      if (
        that.state.establishmentId &&
        establishmentMercurialId !== product.mercurial_id
      )
        continue;

      familiesUniques[product.famille] = {
        mercurialId: product.mercurial_id,
        fam: product.fam,
      };
    }
    if (
      !Util.emptyString(that.state.establishmentId) ||
      !Util.emptyString(that.state.mercurialId)
    ) {
      if (Object.keys(familiesUniques).length === 0) resolve([]);
      let response = Array.from({length: Object.keys(familiesUniques).length},()=>null);
      Object.keys(familiesUniques).map(async (famille, i) => {
        let success = (res) => {
          response.splice(res.filter((r) => { return r.famille === famille })[0].num,1,(<div
            className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 mb-4 mx-auto mw-400"
            key={"fam-" + familiesUniques[famille].fam}
          >
            <Link
              to={
                "/home/products/mercurial/" +
                familiesUniques[famille].mercurialId +
                "/" +
                encodeURI(famille)
              }
              className="text-decoration-none"
            >
              <div className="card h-300">
                <div className="card-header text-center">
                  <h6 className="text-info mb-0">
                    <strong>{famille}</strong>
                  </h6>
                </div>
                <div className="h-350">
                  <ReactImageFallback
                    src={imgSrc}
                    fallbackImage="/images/no_image_512.png"
                    initialImage="/images/loader.gif"
                    alt=""
                    className="d-block align-middle mx-auto blocFamilies"
                  />
                </div>
              </div>
            </Link>
            {res.filter((r) => { return r.famille === famille })[0].res}
          </div>));
          if (i + 1 >= Object.keys(familiesUniques).length) {
            resolve(response);
          }
        }
        // Href link
        let replaceImgBtn = [];
        var imgSrc =
          APIUrl.getFamilyImg +
          familiesUniques[famille].mercurialId +
          "/FAM_" +
          familiesUniques[famille].fam.split(".")[0] +
          "?token=" +
          APIUrl.jwtToken;
        await fetch(APIUrl.checkFamilyImg +
          familiesUniques[famille].mercurialId +
          "/FAM_" +
          familiesUniques[famille].fam.split(".")[0] +
          "?token=" +
          APIUrl.jwtToken).then(async (response) => {
            response.json().then((res) => {
              replaceImgBtn.push({
                num: i, ref: res.response.ref, famille: famille, res: res.response.res ? (
                  <MenuButton
                    size="sm"
                    className="btn-block text-danger"
                    icon="trash"
                    variant="light"
                    onClick={() =>
                      that.openCustomFamilyImgRemoveModal(
                        familiesUniques[famille],
                        imgSrc
                      )
                    }
                  >
                    <FormattedMessage id="Delete.Custom.Image" />
                  </MenuButton>
                ) : (
                  <MenuButton
                    size="sm"
                    icon="upload"
                    variant="light"
                    className="btn-block text-info"
                    onClick={() =>
                      that.openCustomFamilyImgUploadModal(
                        familiesUniques[famille],
                        imgSrc,
                        imgSrc
                      )
                    }
                  >
                    <FormattedMessage id="Replace.Default.Image" />
                  </MenuButton>
                )
              });
              success(replaceImgBtn);
            })
          })
      })
    }
  }

  render() {
    var mercurialsNode = this.props.mercurials.map((mercurial) => {
      var now = new Date();
      var hasExpired =
        new Date(mercurial.start_date) > now ||
        new Date(mercurial.end_date) < now;
      if (hasExpired) return null;
      if (mercurial.status === MercurialStatus.INACTIVE) return null;

      return (
        <option key={mercurial._id} value={mercurial._id}>
          {mercurial.name}
        </option>
      );
    });

    var mercurialsSelectNode = (
      <div role="alert">
        <select
          id="mercurials-filter-establishmentId"
          className="form-control w-100"
          value={this.state.mercurialId}
          onChange={(e) => this.selectMercurial(e.target.value)}
        >
          <option value="">
            {this.props.intl.formatMessage({ id: "Select.Mercurial" })}...
          </option>
          {mercurialsNode}
        </select>
      </div>
    );
    // Filters

    var establishmentsNode = this.props.establishments.map((establishment) => {
      return (
        <option key={establishment._id} value={establishment._id}>
          {establishment.name}
        </option>
      );
    });
    var establishmentSelectNode = (
      <div role="alert">
        <select
          id="families-filter-establishmentId"
          className="form-control w-100"
          value={this.state.establishmentId}
          onChange={(e) => this.selectEstablishment(e.target.value)}
        >
          <option value="">
            {this.props.intl.formatMessage({ id: "Select.Establishment" })}...
          </option>
          {establishmentsNode}
        </select>
      </div>
    );
    if (this.state.establishmentId !== "") {
      const displayNoMercurialAffected = () => {
        return (
          <React.Fragment>
            <TableToolbar>
              <div className="col-md-3">{establishmentSelectNode}</div>
              <div className="col-md-3">{mercurialsSelectNode}</div>
            </TableToolbar>
            <div className="alert alert-secondary" role="alert">
              <FormattedMessage id="No.Affected.Mercurial" />
            </div>
          </React.Fragment>
        );
      }

      const establishmentMercurialId = this.getMercurialIdByEstablishmentId(this.state.establishmentId);
      if (!establishmentMercurialId) return displayNoMercurialAffected();
      
      const mercurial = this.getMercurial(establishmentMercurialId);
      if (!establishmentMercurialId || !mercurial) return displayNoMercurialAffected();

      const now = new Date();
      const hasExpired = new Date(mercurial.start_date) > now || new Date(mercurial.end_date) < now;
      const isInactive = mercurial.status === MercurialStatus.INACTIVE;
      if (hasExpired || isInactive) return displayNoMercurialAffected();
    }

    // No 'families'? No render
    if (this.props.products.length === 0) {
      return (
        <React.Fragment>
          <TableToolbar>
            <div className="col-md-3">{establishmentSelectNode}</div>
            <div className="col-md-3">{mercurialsSelectNode}</div>
          </TableToolbar>
          <div className="alert alert-secondary" role="alert">
            <FormattedMessage id="Empty.Families" />
          </div>
        </React.Fragment>
      );
    }

    var establishmentMercurialId = null;
    if (
      this.state.establishmentId &&
      !Util.emptyString(this.state.establishmentId)
    ) {
      for (let establishmentsSetting of this.props.establishmentsSettings) {
        if (
          establishmentsSetting.establishment_id === this.state.establishmentId
        ) {
          establishmentMercurialId = establishmentsSetting.mercurial_id;
          break;
        }
      }
    }

    var familiesUniques = {};
    for (let product of this.props.products) {
      // Mercurials filter
      if (
        this.state.mercurialId &&
        !Util.emptyString(this.state.mercurialId) &&
        this.state.mercurialId !== product.mercurial_id
      )
        continue;

      // Establishment filter
      if (
        this.state.establishmentId &&
        establishmentMercurialId !== product.mercurial_id
      )
        continue;

      familiesUniques[product.famille] = {
        mercurialId: product.mercurial_id,
        fam: product.fam,
      };
    }

    var filteredProducts = [];
    var mercurialId = this.state.mercurialId;

    let establishment = this.props.establishments.find(
      (e) => e._id === this.state.establishmentId
    );

    if (establishment && !Util.emptyObject(establishment)) {
      var establishmentSettings = this.props.establishmentsSettings.find(
        (establishmentSettings) =>
          establishmentSettings.establishment_id === establishment._id
      );
    }

    if (
      Util.emptyString(this.state.mercurialId) &&
      establishmentSettings &&
      establishmentSettings.mercurial_id
    ) {
      filteredProducts = this.props.products.filter(
        (p) => p.mercurial_id === establishmentSettings.mercurial_id
      );
      mercurialId = establishmentSettings.mercurial_id;
    } else {
      filteredProducts = this.props.products.filter(
        (p) => p.mercurial_id === this.state.mercurialId
      );
    }

    let suggestions = [];

    filteredProducts.forEach((element) => {
      let obj = {};
      obj.designation = element.designation;
      obj.caracteristiques = element.caracteristiques;
      obj.ref = element.ref;
      obj.mercurial_id = element.mercurial_id;
      obj.ref_frn = element.ref_frn;
      obj.famille = element.famille;
      obj.sous_famille = element.sous_famille;
      suggestions.push(obj);
    });

    return (
      <React.Fragment>
        <TableToolbar>
          <div className="col-md-3">{establishmentSelectNode}</div>
          <div className="col-md-3">{mercurialsSelectNode}</div>
          <div className="col-md-6">
            {(!Util.emptyString(this.state.establishmentId) ||
              !Util.emptyString(this.state.mercurialId)) && (
                <AutoSuggestProducts
                  id="search"
                  name="search"
                  onBlur={(e) => this.update("name", e.target.value)}
                  onSuggestionSelected={(value) =>
                    this.onSearch(value, filteredProducts, mercurialId)
                  }
                  suggestions={suggestions}
                  placeholder={"Search.Product.Placeholder"}
                />
              )}
          </div>
        </TableToolbar>

        <div className="row">{this.state.imageFamilyRender}</div>
        {this.state.modal}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    lang: state.i18n.lang,
    products: state.products,
    mercurials: state.mercurials,
    establishments: state.establishments,
    establishmentsSettings: state.establishmentsSettings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetProductsByMercurialIdAdmin: (mercurialId) =>
      dispatch(getProductsByMercurialIdAdmin(mercurialId)),
    onGetProductsByEstablishmentIdAdmin: (establishmentId) =>
      dispatch(getProductsByEstablishmentIdAdmin(establishmentId)),
    onRemovefamilyImgs: (mercurialId, data, successCallback) => dispatch(removeFamilyImgs(mercurialId, data, successCallback)),
    onAddFamilyImgs: (mercurialId, data, successCallback) => dispatch(addFamilyImgs(mercurialId, data, successCallback))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(FamiliesAdmin));
