import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import PriceUtil from "../../util/PriceUtil";
import Roles from "../../enums/Roles";
import Util from "../../util/Util";
import ProductsParserUtil from "../../util/ProductsParserUtil";
import APIUrl from "../../APIUrl";
import { SideBySideMagnifier } from "react-image-magnifiers";
import { addFavorite, deleteFavorite } from "../../actions/user/user";
import { NotificationManager } from "react-notifications";
import { Button } from "react-bootstrap";
import { addProductsImgs } from "../../actions/mercurials/mercurials";
import Icon from "../sub/Icon";
import "react-notifications/lib/notifications.css";
import MenuButton from "../sub/bootstrap/MenuButton";

class ProductRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      // Check if this component is displayed after a specific product (aka specific packaging for a product for example) search or not (list of products)
      _formatId:
        Util.typeOf(this.props.searchProductId) !== "Undefined" &&
          Util.typeOf(this.props.searchProductId) !== "Null"
          ? this.props.searchProductId
          : this.props.product._id,
      //_formatId: this.props.product._id,
      disableButton: false,
      quantity: 0,
    };
  }

  changeFormat(e) {
    e.preventDefault();
    e.stopPropagation();

    // Change state so that it now focuses on correct product format
    this.setState({ _formatId: e.target.value });
  }

  changeQuantity(e) {
    this.setState({ quantity: e.target.value });
  }

  add(e, product, quantity) {
    if (
      this.state.quantity < this.props.product.min_cde ||
      this.state.disableButton
    )
      return;
    // Disable button
    this.setState({ disableButton: true });

    if (!quantity)
      quantity = document
        .getElementById("product-" + product._id)
        .querySelector("#quantity").value;

    var successCallback = () => {
      this.setState({ disableButton: false, quantity: 0 });

      var designation = product.designation;
      if (designation[designation.length - 1] === "-")
        designation = designation.slice(0, designation.length - 2);

      let message =
        this.props.intl.formatMessage({ id: "Cart.Add.Success" }) +
        " " +
        designation;

      NotificationManager.info(message);
    };

    // Call parent method
    this.props.onAdd(e, this.state._formatId, quantity, successCallback);
  }

  addMin(e, product) {
    this.add(e, product, product.min_cde);
  }

  getProduct(id) {
    // Id is main product. Return main product
    if (this.props.product._id === id) return this.props.product;

    // Id corresponds to a specific format. Use this format instead
    for (let format of this.props.product.formats) {
      if (format._id === id) return format;
    }
  }

  validMaxQuantity(value) {
    if (value > 1000) {
      this.setState({ quantity: 1000 });
    }
  }

  isClient() {
    return (
      this.props.user.role !== Roles.SALES_REP &&
      this.props.user.role !== Roles.ADMIN
    );
  }

  setFavorite(ref_frn) {
    let data = { ref_frn: ref_frn };
    let message;

    var successCallback = (message) => {
      NotificationManager.success(message);
    };

    if (!this.checkFavorite(ref_frn)) {
      message = this.props.intl.formatMessage({ id: "Favorite.Added" });
      this.props.onUpdateFavorite(data, successCallback(message));
    } else {
      message = this.props.intl.formatMessage({ id: "Favorite.Removed" });
      this.props.onDeleteFavorite(data, successCallback(message));
    }
  }

  isDisabled() {
    return (
      this.state.quantity < this.props.product.min_cde ||
      this.state.disableButton
    );
  }

  checkFavorite(ref_frn) {
    if (this.props.user.favorite_products) {
      for (let favorite of this.props.user.favorite_products) {
        if (favorite.ref_frn === ref_frn) {
          return true;
        }
      }
    }
    return false;
  }

  render() {
    let productFormat = this.getProduct(this.state._formatId);

    let favoriteBtnClass = "outline-success";
    let favoriteIconClass = "star";
    let favoriteMessage = <FormattedMessage id="Favorite.Add" />;

    if (this.checkFavorite(this.props.product.ref_frn)) {
      favoriteBtnClass = "success";
      favoriteIconClass = "star";
      favoriteMessage = <FormattedMessage id="Favorite.Remove" />;
    }

    // Show a select list of the formats only if more than 1 format
    let formats = null;
    if (this.props.product.formats.length === 1) {
      // formats = (<div>{this.props.product.formats[0].format}</div>);
      formats = "";
    } else {
      formats = (
        <select
          className="form-control"
          onChange={(e) => this.changeFormat(e)}
          defaultValue={this.state._formatId}
        >
          {this.props.product.formats.map((format) => (
            <option key={format.format} value={format._id}>
              {format.format}
            </option>
          ))}
        </select>
      );
    }

    var quantity = (
      <td className="d-none d-md-table-cell col-1">
        <div>
          <input
            id="quantity"
            type="number"
            value={parseInt(this.state.quantity)}
            min="0"
            max="1000"
            onBlur={() => this.validMaxQuantity(this.state.quantity)}
            step={this.props.product.min_cde}
            onChange={(e) => this.changeQuantity(e, this.props.product)}
            onKeyDown={(e) => {
              if (e.key === "Enter") this.add(e, this.props.product);
            }}
            className="product-quantity-input"
          />
        </div>
      </td>
    );
    var addButton = (
      <td className="d-none d-md-table-cell col-1">
        <MenuButton
          icon="cart-plus"
          className="mr-1 mb-1"
          onClick={(e) => this.add(e, this.props.product)}
          disabled={
            this.state.quantity < this.props.product.min_cde ||
            this.state.disableButton
          }
          hover={!(
            this.state.quantity < this.props.product.min_cde ||
            this.state.disableButton
          ) ? (
            <FormattedMessage id="Basket.Add" />
          ) : (
            <FormattedMessage id="Basket.Set.Quantity" />
          )}
        />
        <MenuButton
          icon={favoriteIconClass}
          variant={favoriteBtnClass}
          onClick={() => this.setFavorite(this.props.product.ref_frn)}
          hover={favoriteMessage}
        />
      </td>
    );
    var addButtonResponsive = (
      <button
        type="button"
        className={"btn btn-info mt-2 rounded product-button d-md-none"}
        disabled={this.state.disableButton}
        onClick={(e) => this.addMin(e, this.props.product)}
      >
        <FormattedMessage id="Add.Cart" />
      </button>
    );

    let replaceImgBtn = null;
    // if user is not client, maybe he will upload new pictures. We avoid caching them to display changes instantly.
    // Client wil use regular browser cache management.
    const forceCacheRefresh =
      this.props.user.role === Roles.CLIENT ? 0 : Math.random();
    const currentProductImgUrl =
      APIUrl.getProductImg +
      this.props.product.mercurial_id +
      "/" +
      productFormat.ref_frn +
      "/" +
      forceCacheRefresh +
      "/" +
      productFormat.ref +
      "/" +
      productFormat.parentProductRefFrn +
      "/?token=" +
      APIUrl.jwtToken;
    const databaseDefaultProductImgUrl =
      APIUrl.getProductImg +
      this.props.product.mercurial_id +
      "/" +
      productFormat.ref_frn +
      "/" +
      forceCacheRefresh +
      "/?token=" +
      APIUrl.jwtToken;

    if (
      this.props.user.role === Roles.SALES_REP ||
      this.props.user.role === Roles.ADMIN
    ) {
      quantity = null;
      addButton = null;
      addButtonResponsive = null;
      replaceImgBtn = productFormat.customImg ? (
        <Button
          size="sm"
          variant="danger"
          onClick={() =>
            this.props.openDeleteProductImgModal(
              productFormat,
              currentProductImgUrl,
              databaseDefaultProductImgUrl
            )
          }
        >
          <Icon icon="trash" clickable className="static" />
        </Button>
      ) : (
        <Button
          size="sm"
          variant="info"
          onClick={() =>
            this.props.openUploadProductImgModal(
              productFormat,
              currentProductImgUrl,
              databaseDefaultProductImgUrl
            )
          }
        >
          <Icon icon={"upload"} className="static" />
        </Button>
      );
    }

    return (
      <tr
        key={this.props.product._id}
        id={"product-" + this.props.product._id}
        className="d-flex"
      >
        <td className="d-none d-lg-table-cell col-2">
          <div className="card-img-top d-block mx-auto img-thumbnail">
            <SideBySideMagnifier
              className="magnifier"
              imageSrc={[currentProductImgUrl, "/images/no_image_512.png"]}
              alwaysInPlace={false}
              switchSides={false}
              zoomPosition="left"
              fillAvailableSpace={true}
              fillAlignTop={false}
              fillGapTop={10}
              fillGapRight={100}
              fillGapBottom={10}
              fillGapLeft={0}
              zoomContainerBorder="1px solid #ccc"
              zoomContainerBoxShadow="0 4px 8px rgba(0,0,0,.5)"
              cursorStyle="zoom-in"
            />
          </div>
          <div className="text-center pt-2">
            {replaceImgBtn}
            {this.props.user.role === Roles.ADMIN && <Button
              size="sm"
              variant="info"
              className="ml-2"
              onClick={() => this.props.openProductModal(productFormat)}
            >
              <Icon icon={"euro-sign"} className="static" />
            </Button>}
          </div>
        </td>
        <td className={this.isClient() ? "col-4" : "col-6"}>
          <div className="text-muted">
            <small>{this.props.product.sous_famille}</small>
          </div>
          <div className="product-sous-famille">
            {ProductsParserUtil.getDesignation(this.props.product)}
          </div>
          <div className="badge badge-info">
            <FormattedMessage id="Ref.Short" />:{" "}
            <span className="product-ref-value">{productFormat.ref}</span>
          </div>
          <div className="product-caracteristiques">
            {this.props.product.caracteristiques}
          </div>
        </td>
        <td className="col-2">
          {formats && (
            <div className="pb-3">
              <div>
                <strong>
                  <FormattedMessage id="Format" /> :
                </strong>
              </div>
              <small>{formats}</small>
            </div>
          )}
          <div>
            <strong>
              <FormattedMessage id="Packaging" /> :
            </strong>
          </div>
          <div>{productFormat.unite_de_vente}</div>

          {addButtonResponsive}
        </td>
        <td className="d-none d-md-table-cell text-right col-1">
          {(this.props.user.role === Roles.SALES_REP ||
            this.props.user.role === Roles.ADMIN) && (
              <div>
                <h6 className="text-info">
                  <strong>
                    {PriceUtil.priceHt(productFormat, 1, 2)}&euro;{" "}
                    <FormattedMessage id="Excl.Tax" />
                  </strong>
                </h6>
                <h6 className="text-secondary">
                  <small>
                    {PriceUtil.priceTtc(productFormat, 1, 2)}&euro;{" "}
                    <FormattedMessage id="Incl.Tax" />
                  </small>
                </h6>
              </div>
            )}
          {this.props.user.role === Roles.CLIENT && (
            <div>
              <h6 className="text-info">
                <strong>
                  {PriceUtil.priceTtc(productFormat, 1, 2)}&euro;{" "}
                  <FormattedMessage id="Incl.Tax" />
                </strong>
              </h6>
              <h6 className="text-secondary">
                <small>
                  {PriceUtil.priceHt(productFormat, 1, 2)}&euro;{" "}
                  <FormattedMessage id="Excl.Tax" />
                </small>
              </h6>
            </div>
          )}
        </td>
        <td className="d-none d-lg-table-cell text-right col-1">
          {PriceUtil.tva(productFormat) * 100}%
        </td>
        {quantity}
        {addButton}
      </tr>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    lang: state.i18n.lang,
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdateFavorite: (data, successCallback) =>
      dispatch(addFavorite(data, successCallback)),
    onDeleteFavorite: (data, successCallback) =>
      dispatch(deleteFavorite(data, successCallback)),
    onAddProductsImg: (mercurialId, data, successCallback) =>
      dispatch(addProductsImgs(mercurialId, data, successCallback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(ProductRow));
