import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import RequestRowAdmin from "./RequestRowAdmin";
import RequestTypes from "../../../enums/RequestTypes";
import RequestStatus from "../../../enums/RequestStatus";
import DateUtil from "../../../util/DateUtil";
import Util from "../../../util/Util";
import RequestModalAdmin from "./RequestModalAdmin";
import Paginator from "../../sub/Paginator";
import CustomLabel from "../../sub/CustomLabel";
import ModalManager from "../../sub/modals/ModalManager";
import {
  getEquipment,
  hasAlreadyRequest,
} from "../../../actions/equipments/equipments";
import { getEstablishment } from "../../../actions/establishments/establishments";
import Icon from "../../sub/Icon";
import TableToolbar from "../../sub/bootstrap/TableToolbar";
import MenuButton from "../../sub/bootstrap/MenuButton";
import ActionMenu from "../../sub/ActionMenu";

class RequestsAdmin extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      modal: null,
      clientFilter: "",
      establishmentFilter: this.props.establishmentId || "",
      requestTypeFilter: "",
      dateFilter: "",
      statusFilter: "",
      interventionRequest: "",
      abortChange: false,
      hasAlreadyRequest: this.props.equipmentId
        ? this.hasAlreadyAnyPendingRequest()
        : false,
      establishmentFilterRequests: this.props.establishmentId || "",
      displayHistory: false
    };

    this.paginator = new Paginator(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.requests !== this.props.requests) {
      this.hasAlreadyAnyPendingRequest();
    }
  }

  onChange(field, value) {
    this.setState({ [field]: value });
  }

  getNameEstablishment(establishmentid) {
    for (let establishment of this.props.establishments) {
      if (establishment._id === establishmentid) {
        return establishment.name;
      }
    }
    return "";
  }

  hasAlreadyRequest(requestType) {
    this.props.onHasAlreadyRequest(
      this.props.equipmentId,
      requestType,
      (result) => this.setState({ hasAlreadyRequest: result })
    );
  }

  hasAlreadyAnyPendingRequest() {
    return this.hasAlreadyRequest(null);
  }

  openRequestModalAdmin(request) {
    if (request) {
      this.props.onGetEquipment(request.equipment_id, (equipment) => {
        this.props.onGetEstablishment(
          equipment.establishment_id,
          (establishment) => {
            this.setState({
              modal: (
                <RequestModalAdmin
                  isOpen={true}
                  equipment={equipment}
                  establishment={establishment}
                  close={() => this.closeModal()}
                  requestType={request.request_type}
                  requestId={request._id}
                  request={request}
                />
              ),
            });
          }
        );
      });
    } else {
      this.setState({
        modal: (
          <RequestModalAdmin
            isOpen={true}
            equipment={this.props.equipment}
            equipments={this.props.equipments}
            establishments={this.props.establishments}
            close={() => this.closeModal()}
          />
        ),
      });
    }
  }

  openConfModal(title, content, successCallback, abortCallback) {
    this.setState({
      modal: (
        <ModalManager
          showModal={true}
          title={title}
          content={content}
          successCallback={successCallback}
          abortCallback={abortCallback}
          closeModal={() => this.closeModal()}
          context="danger"
          modalType="confirmation"
        />
      ),
    });
  }

  closeModal() {
    this.hasAlreadyAnyPendingRequest();
    this.setState({ modal: null });
  }

  getEstablishment(request) {
    let writenByYou = this.props.user._id === request.author_id;
    for (let establishment of this.props.establishments) {
      if (request.establishment_id === establishment._id && writenByYou) {
        return (
          <div>
            {request.client_name}
            <div className="font-weight-lighter">
              ({<FormattedMessage id="On.Behalf.Of" />})
            </div>
          </div>
        );
      } else if (
        request.establishment_id === establishment._id &&
        !writenByYou
      ) {
        return <div>{request.client_name}</div>;
      }
    }
  }

  areResultsFiltered = () => {
    if (
      (!this.props.equipmentId && !Util.emptyString(this.state.establishmentFilter)) ||
      !Util.emptyString(this.state.requestTypeFilter) ||
      !Util.emptyString(this.state.dateFilter) ||
      !Util.emptyString(this.state.statusFilter)
    );
  };

  noRequestInEstablishment(establishmentId) {
    for (let m of this.props.requests) {
      if (m.establishment_id === establishmentId) {
        return false;
      }
    }
    return true;
  }

  resetSearchFields() {
    this.setState({
      establishmentFilter: "",
      requestTypeFilter: "",
      dateFilter: "",
      statusFilter: "",
      establishmentFilterRequests: this.props.establishmentId ? this.props.establishmentId : ""
    });
  }

  updateChildState(data) {
    this.setState(data);
  }

  render() {

    let requests = this.props.requests;
    let filterEstablishmentList = [];
    if (this.props.equipmentId) {
      for (var r of requests) {
        if (r.equipment_id === this.props.equipmentId) {
          let isInFilter = false;
          for (let filterEstablishment of filterEstablishmentList) {
            if (filterEstablishment._id.toString() === r.establishment_id.toString()) {
              isInFilter = true;
              break;
            }
          }
          if (filterEstablishmentList.length === 0 || isInFilter === false) {
            filterEstablishmentList.push({ _id: r.establishment_id, name: this.getNameEstablishment(r.establishment_id) });
          }
        }
      }
      requests = requests.filter((request) => {
        if (request.establishment_id === this.state.establishmentFilterRequests || this.state.establishmentFilterRequests === "") {
          return request.equipment_id === this.props.equipmentId;
        } else {
          return false;
        }
      });
      if (this.noRequestInEstablishment(this.props.establishmentId) && (this.props.establishmentId !== this.props.company._id)) {
        filterEstablishmentList.push({ _id: this.props.establishmentId, name: this.getNameEstablishment(this.props.establishmentId) });
      }
    }

    this.paginator.init();

    let disableFormInput = this.paginator.paginationIndex !== 1 ? true : false;

    var i = 0;

    let requestRowNode = requests.map((request) => {
      if (request.request_type === RequestTypes.NEW_MATERIAL) return null;

      if (this.props.equipmentId) {
        if (this.state.clientFilter && this.state.clientFilter !== "") {
          if (request.client_id !== this.state.clientFilter) return null;
        }
      }
      else {
        if (this.state.establishmentFilter && this.state.establishmentFilter !== "") {
          if (request.establishment_id !== this.state.establishmentFilter) return null;
        }
      }

      if (this.state.requestTypeFilter && this.state.requestTypeFilter !== "") {
        if (
          request.request_type === 1 &&
          request.type_of_renewal === 0 &&
          parseInt(this.state.requestTypeFilter) !== 1
        ) {
          return null;
        } else if (
          request.request_type === 1 &&
          request.type_of_renewal === 1 &&
          parseInt(this.state.requestTypeFilter) !== 3
        ) {
          return null;
        } else if (
          request.type_of_renewal === undefined &&
          parseInt(request.request_type) !==
          parseInt(this.state.requestTypeFilter)
        ) {
          return null;
        }
      }

      if (this.state.dateFilter && this.state.dateFilter !== "") {
        if (
          DateUtil.toDate(request.creation_date) !==
          DateUtil.toDate(this.state.dateFilter)
        )
          return null;
      }

      if (this.state.statusFilter && this.state.statusFilter !== "") {
        if (parseInt(request.status) !== parseInt(this.state.statusFilter))
          return null;
      }

      if (this.props.limit && ++i > this.props.limit) return null;

      if (!this.paginator.keep()) return null;

      let establishment = this.getEstablishment(request);

      return (
        <RequestRowAdmin
          includeType={true}
          key={request._id}
          request={request}
          updateParentState={this.updateChildState.bind(this)}
          materialType={request.material_type_name}
          establishment={establishment}
          establishmentId={request.establishment_id}
          equipment={this.props.equipment}
          openRequestModalAdmin={(request) =>
            this.openRequestModalAdmin(request)
          }
          openConfModal={(title, content, successCallback, abortCallback) =>
            this.openConfModal(title, content, successCallback, abortCallback)
          }
          closeModal={() => this.closeModal()}
          limit={this.props.limit}
        />
      );
    });

    if (!this.props.equipmentId) {
      var establishmentsNode = this.props.establishments.map((establishment) => {
        return (
          <option key={establishment._id} value={establishment._id}>
            {establishment.name}
          </option>
        );
      });
    }

    let requestTypeFilterNode = Object.values(RequestTypes).map(
      (RequestType) => {
        if (RequestType === RequestTypes.NEW_MATERIAL) return null;
        if (RequestType === RequestTypes.RENEWAL) return null;

        return (
          <option key={RequestType} value={RequestType}>
            {this.props.intl.formatMessage({
              id: "Request.Type." + RequestType,
            })}
          </option>
        );
      }
    );

    let statusFilterNode = Object.values(RequestStatus).map((requestS) => {
      return (
        <option key={requestS} value={requestS}>
          {this.props.intl.formatMessage({ id: "Request.Status." + requestS })}
        </option>
      );
    });

    let menuAction;

    if (this.props.equipmentId) {
      let menuItems = [];

      menuItems.push({
        icon: "circle-plus",
        disabled: this.state.hasAlreadyRequest,
        action: () => { this.openRequestModalAdmin() },
        text: <FormattedMessage id="Request.Intervention" />,
      });

      menuItems.push({
        icon: "history",
        disabled: (!filterEstablishmentList.length || (filterEstablishmentList.length === 1 && (filterEstablishmentList[0]["_id"] === this.props.establishmentId)) || this.state.displayHistory) ? true : false,
        action: () => { this.onChange("displayHistory", !this.state.displayHistory) },
        text: <FormattedMessage id="Previous.History" />,
      });

      menuAction = <ActionMenu
        className="ml-auto"
        items={menuItems}
        label={<FormattedMessage id="Actions" />}
        text="Actiu"
        size="2xl"
      />;
    }
    else {
      menuAction = <button
        className="btn btn-info ml-auto"
        disabled={this.state.hasAlreadyRequest}
        onClick={(e) => { this.openRequestModalAdmin() }}
      >
        <Icon icon="circle-plus" className="mr-2" />
        <FormattedMessage id="Request.Intervention" />
      </button>
    }

    return (
      <React.Fragment>
        {(requests && requests.length > 0) &&
          <React.Fragment>
            {!this.props.limit && (
              <div>
                {this.state.hasAlreadyRequest && <div className="alert alert-warning"><FormattedMessage id="Dm.Ongoing.Request" /></div>}
                <TableToolbar>
                  <React.Fragment>
                    {(this.state.displayHistory === false) &&
                      <React.Fragment>
                        {!this.props.equipmentId && (
                          <>
                            <CustomLabel
                              label={this.props.intl.formatMessage({
                                id: "Client",
                              })}
                              htmlFor="search_client"
                              labelClassName="my-1 mr-2"
                            />
                            <select
                              id="search_client"
                              className="form-control mr-sm-4"
                              onChange={(e) => {
                                this.setState({
                                  establishmentFilter: e.target.value,
                                });
                              }}
                              value={this.state.establishmentFilter}
                              disabled={disableFormInput}
                            >
                              <option value="">
                                {this.props.intl.formatMessage({ id: "All" })}
                              </option>
                              {establishmentsNode}
                            </select>
                          </>
                        )}
                        <CustomLabel
                          label={this.props.intl.formatMessage({
                            id: "Type",
                          })}
                          htmlFor="search_type"
                          labelClassName="my-1 mr-2"
                        />
                        <select
                          id="search_type"
                          className="form-control mr-sm-4"
                          onChange={(e) => {
                            this.setState({
                              requestTypeFilter: e.target.value,
                            });
                          }}
                          value={this.state.requestTypeFilter}
                          disabled={disableFormInput}
                        >
                          <option value="">
                            {this.props.intl.formatMessage({ id: "All" })}
                          </option>
                          <option value={1}>
                            {this.props.intl.formatMessage({
                              id: "Request.Type." + 1,
                            })}
                          </option>
                          <option value={3}>
                            {this.props.intl.formatMessage({
                              id: "Request.Type." + 3,
                            })}
                          </option>
                          {requestTypeFilterNode}
                        </select>
                        <CustomLabel
                          label={this.props.intl.formatMessage({
                            id: "Date",
                          })}
                          htmlFor="search_date"
                          labelClassName="my-1 mr-2"
                        />
                        <input
                          id="search_date"
                          className="form-control mr-sm-4"
                          type="date"
                          onChange={(e) => {
                            this.setState({ dateFilter: e.target.value });
                          }}
                          value={this.state.dateFilter}
                          disabled={disableFormInput}
                        />
                        <CustomLabel
                          label={this.props.intl.formatMessage({
                            id: "Status",
                          })}
                          htmlFor="search_status"
                          labelClassName="my-1 mr-2"
                        />
                        <select
                          id="search_status"
                          className="form-control mr-sm-4"
                          onChange={(e) => {
                            this.setState({ statusFilter: e.target.value });
                          }}
                          value={this.state.statusFilter}
                          disabled={disableFormInput}
                        >
                          <option value="">
                            {this.props.intl.formatMessage({ id: "All" })}
                          </option>
                          {statusFilterNode}
                        </select>
                        <MenuButton
                          onClick={() => this.resetSearchFields()}
                          hover={(this.areResultsFiltered() && !disableFormInput) && <FormattedMessage id="Remove.Filter" />}
                          variant={this.areResultsFiltered() ? "warning" : "outline-secondary"}
                          icon="filter"
                          disabled={!this.areResultsFiltered() || disableFormInput}
                        />
                      </React.Fragment>}

                    {this.state.displayHistory === true &&
                      <React.Fragment>
                        <CustomLabel
                          label={this.props.intl.formatMessage({
                            id: "Client",
                          })}
                          htmlFor="search_client"
                          labelClassName="my-1 mr-2"
                        />
                        <select className="form-control mr-2" onChange={(e) => this.onChange("establishmentFilterRequests", e.target.value)} defaultValue={this.state.establishmentFilterRequests}>
                          <option key="empty" value="">
                            {this.props.intl.formatMessage(({ id: "All" }))}
                          </option>
                          {filterEstablishmentList.map((e) => {
                            return <option key={e._id} value={e._id}>{e.name}</option>;
                          })}
                        </select>
                        <MenuButton
                          size="sm"
                          className="ml-1"
                          onClick={(e) => { this.onChange("displayHistory", !this.state.displayHistory); this.onChange("establishmentFilterRequests", this.state.establishmentFilter) }}
                        >
                          <Icon icon="times-circle" className="" />
                        </MenuButton>
                      </React.Fragment>
                    }

                  </React.Fragment>
                  {menuAction}
                </TableToolbar>
              </div>
            )}

            <React.Fragment>
              <table className="table table-striped tablee4coll">
                <thead>
                  <tr className="d-flex">
                    <th scope="col" className="col">
                      <FormattedMessage id="Buyer" />
                    </th>
                    <th scope="col" className="col">
                      <FormattedMessage id="Type" />
                    </th>
                    <th scope="col" className="col">
                      <FormattedMessage id="Medical.Device" />
                    </th>
                    <th scope="col" className="col">
                      <FormattedMessage id="Request" />
                    </th>
                    <th
                      scope="col"
                      className={!this.props.limit ? "col col-2" : "col"}
                    >
                      <FormattedMessage id="Date" />
                    </th>
                    <th
                      scope="col"
                      className={!this.props.limit ? "col col-2" : "col"}
                    >
                      <FormattedMessage id="Status" />
                    </th>
                    {(!this.props.limit && (this.props.equipment ? (this.props.equipment.establishment_id) : true)) && (
                      <th scope="col" className="col col-1 text-center">
                        <FormattedMessage id="Actions" />
                      </th>
                    )}
                  </tr>
                </thead>
                <tbody>{requestRowNode}</tbody>
              </table>

              {!this.props.limit && this.paginator.render()}
            </React.Fragment>
          </React.Fragment>
        }

        {
          (requests && requests.length < 1) &&
          <React.Fragment>
            <TableToolbar>
              {this.state.displayHistory === true &&
                <React.Fragment>
                  <CustomLabel
                    label={this.props.intl.formatMessage({
                      id: "Client",
                    })}
                    htmlFor="search_client"
                    labelClassName="my-1 mr-2"
                  />
                  <select className="form-control mr-2" onChange={(e) => this.onChange("establishmentFilterRequests", e.target.value)} defaultValue={this.state.establishmentFilterRequests}>
                    <option key="empty" value="">
                      {this.props.intl.formatMessage(({ id: "All" }))}
                    </option>
                    {filterEstablishmentList.map((e) => {
                      return <option key={e._id} value={e._id}>{e.name}</option>;
                    })}
                  </select>
                  <MenuButton
                    size="sm"
                    className="ml-1"
                    onClick={(e) => { this.onChange("displayHistory", !this.state.displayHistory); this.onChange("establishmentFilterRequests", this.state.establishmentFilter) }}
                  >
                    <Icon icon="times-circle" className="" />
                  </MenuButton>
                </React.Fragment>
              }
              {menuAction}
            </TableToolbar>
            <div className="alert alert-secondary" role="alert">
              <FormattedMessage id="Empty.Requests" />
            </div>
          </React.Fragment>
        }

        {this.state.modal}
      </React.Fragment >
    );
  }
}

const mapStateToProps = (state) => {
  return {
    requests: state.requests,
    materialsTypes: state.materialsTypes,
    clients: state.clients,
    equipments: state.equipments,
    user: state.user,
    establishments: state.establishments,
    company: state.company
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetEquipment: (equipmentId, successCallback) =>
      dispatch(getEquipment(equipmentId, successCallback)),
    onGetEstablishment: (establishmentId, successCallback) =>
      dispatch(getEstablishment(establishmentId, successCallback)),
    onHasAlreadyRequest: (equipmentId, requestType, successCallback) =>
      dispatch(hasAlreadyRequest(equipmentId, requestType, successCallback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(RequestsAdmin));
