import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import ClientsModalAdmin from "./ClientsModalAdmin";
import { getEstablishmentTypes } from "../../actions/configurables/establishmentTypes";
import { getPaymentMethods } from "../../actions/configurables/paymentMethods";
import { getPaymentDeadlines } from "../../actions/configurables/paymentDeadlines";
import EstablishmentsRow from "./EstablishmentsRow";
import Paginator from "../sub/Paginator";
import Util from "../../util/Util";
import CustomLabel from "../sub/CustomLabel";
import TableToolbar from "../sub/bootstrap/TableToolbar";
import MenuButton from "../sub/bootstrap/MenuButton";

class Establishments extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      nameFilter: "",
      addressFilter: "",
      mercurialFilter: "",
      modal: null,
    };

    this.paginator = new Paginator(this);
  }

  componentDidMount() {
    if (this.props.establishmentTypes.length === 0)
      this.props.onGetEstablishmentTypes();
    if (this.props.paymentMethods.length === 0)
      this.props.onGetPaymentMethods();
    if (this.props.paymentDeadlines.length === 0)
      this.props.onGetPaymentDeadlines();
  }

  openModal(establishment, target) {
    this.setState({
      modal: (
        <ClientsModalAdmin
          isOpen={true}
          establishment={establishment}
          openModal={(establishment, target) =>
            this.openModal(establishment, target)
          }
          closeModal={() => this.closeModal()}
          target={target}
        />
      ),
    });
  }

  closeModal() {
    this.setState({ modal: null });
  }

  getSettings(establishmentId) {
    for (let s of this.props.establishmentsSettings) {
      if (s.establishment_id === establishmentId) return s;
    }
  }

  getMercurialName(establishmentId) {
    var settings = this.getSettings(establishmentId);

    // If no settings, or mercurial wasn't defined yet
    if (!settings || !settings.mercurial_id) return null;

    for (let m of this.props.mercurials) {
      if (m._id === settings.mercurial_id) return m.name;
    }
  }

  getClients(establishmentId) {
    var clients = [];

    for (let client of this.props.clients) {
      if (client.establishment_id === establishmentId) {
        clients.push(client);
      }
    }

    return clients;
  }

  areResultsFiltered = () => {
    if (
      !Util.emptyString(this.state.nameFilter) ||
      !Util.emptyString(this.state.addressFilter) ||
      !Util.emptyString(this.state.mercurialFilter)
    ) {
      return true;
    } else {
      return false;
    }
  };

  resetSearchFields() {
    this.setState({
      nameFilter: "",
      addressFilter: "",
      mercurialFilter: "",
    });
  }

  render() {
    if (
      this.props.establishments.length === 0 &&
      this.props.clients.length === 0
    ) {

      let content = <button
        className="btn btn-info ml-auto"
        onClick={(e) => this.openModal()}
      >
        <FormattedMessage id="Establishment.Add" />
      </button>

      return <React.Fragment>
        <TableToolbar>
          {content}
        </TableToolbar>
        {this.state.modal}
      </React.Fragment>

    }

    this.paginator.init();

    let disableFormInput = this.paginator.paginationIndex !== 1 ? true : false;

    // https://stackoverflow.com/questions/37308719/react-component-wait-for-required-props-to-render
    // https://zaiste.net/posts/javascript-destructuring-assignment-default-values/
    const { enabled: crmEnabled = false, software: crmSoftware = null } = this
      .props.company.crm
      ? this.props.company.crm
      : {};

    var establishmentsNodes = this.props.establishments.map((establishment) => {
      if (this.state.nameFilter && this.state.nameFilter !== "") {
        if (
          establishment.name
            .toUpperCase()
            .indexOf(this.state.nameFilter.toUpperCase()) === -1
        )
          return null;
      }

      if (this.state.addressFilter && this.state.addressFilter !== "") {
        if (
          // https://stackoverflow.com/questions/42394239/touppercase-is-not-a-function
          (!establishment.address ||
            establishment.address
              .toString()
              .toUpperCase()
              .indexOf(this.state.addressFilter.toUpperCase()) === -1) &&
          (!establishment.postal_code ||
            establishment.postal_code
              .toString()
              .toUpperCase()
              .indexOf(this.state.addressFilter.toUpperCase()) === -1) &&
          (!establishment.city ||
            establishment.city
              .toString()
              .toUpperCase()
              .indexOf(this.state.addressFilter.toUpperCase()) === -1)
        )
          return null;
      }

      var settings = this.getSettings(establishment._id);
      if (this.state.mercurialFilter && this.state.mercurialFilter !== "") {
        if (
          !settings.mercurial_id ||
          settings.mercurial_id !== this.state.mercurialFilter
        )
          return null;
      }

      if (!this.paginator.keep()) return null;

      return (
        <EstablishmentsRow
          key={establishment._id}
          establishment={establishment}
          openModal={(establishment) => this.openModal(establishment)}
          displayAPIModal={(establishment) =>
            this.openModal(establishment, "apiConfiguration")
          }
          clients={this.getClients(establishment._id)}
          limit={this.props.limit && true}
        />
      );
    });

    // Mercurials select choices
    var now = new Date();
    var mercurialsNode = this.props.mercurials.map((mercurial) => {
      if (
        new Date(mercurial.start_date) > now ||
        new Date(mercurial.end_date) < now
      )
        return null;

      let version = null;
      if (mercurial.version !== 1) version = "(v" + mercurial.version + ")";

      return (
        <option key={mercurial._id} value={mercurial._id}>
          {mercurial.name} {version}{" "}
        </option>
      );
    });

    return (
      <React.Fragment>
        {!this.props.limit && (
          <TableToolbar>
            <CustomLabel
              label={this.props.intl.formatMessage({
                id: "Establishment",
              })}
              htmlFor="search_etablissement"
              labelClassName="my-1 mr-2"
            />
            <input
              id="search_etablissement"
              className="form-control mr-sm-3"
              type="search"
              onChange={(e) => {
                this.setState({ nameFilter: e.target.value });
              }}
              placeholder={this.props.intl.formatMessage({
                id: "Name",
              })}
              disabled={disableFormInput}
              value={this.state.nameFilter}
            />
            <CustomLabel
              label={this.props.intl.formatMessage({ id: "Address" })}
              htmlFor="search_address"
              labelClassName="my-1 mr-2"
            />
            <input
              id="search_address"
              className="form-control mr-sm-3"
              type="search"
              onChange={(e) => {
                this.setState({ addressFilter: e.target.value });
              }}
              placeholder={this.props.intl.formatMessage({
                id: "Address",
              })}
              disabled={disableFormInput}
              value={this.state.addressFilter}
            />
            <CustomLabel
              label={this.props.intl.formatMessage({ id: "Mercurial" })}
              htmlFor="search_mercurials"
              labelClassName="my-1 mr-2"
            />
            <select
              id="search_mercurials"
              className="form-control mr-sm-3"
              onChange={(e) => {
                this.setState({ mercurialFilter: e.target.value });
              }}
              disabled={disableFormInput}
              value={this.state.mercurialFilter}
            >
              <option value="">
                {this.props.intl.formatMessage({ id: "All.Fem" })}
              </option>
              {mercurialsNode}
            </select>

            <MenuButton
              onClick={() => this.resetSearchFields()}
              hover={(this.areResultsFiltered() && !disableFormInput) && <FormattedMessage id="Remove.Filter" />}
              variant={this.areResultsFiltered() ? "warning" : "outline-secondary"}
              icon="filter"
              disabled={!this.areResultsFiltered() || disableFormInput}
            />

            {this.props.user.role !== 2 && (
              <button
                className="btn btn-info ml-auto"
                onClick={(e) => this.openModal()}
              >
                <FormattedMessage id="Establishment.Add" />
              </button>
            )}
          </TableToolbar>
        )}

        <table className="table tablee4coll">
          <thead>
            <tr>
              <th scope="col">
                <FormattedMessage id="Establishment" />
              </th>
              <th scope="col">
                <FormattedMessage id="Address" />
              </th>
              <th scope="col">
                <FormattedMessage id="Mercurial" />
              </th>
              <th scope="col">
                <FormattedMessage id="Sales.Rep" />
              </th>
              {crmEnabled && (
                <th scope="col" className="text-center">
                  <FormattedMessage
                    id="API.CRM.App.Link"
                    values={{ crmSoftware: crmSoftware }}
                  />
                </th>
              )}
              {!this.props.limit && (
                <th scope="col" className="text-center">
                  <FormattedMessage id="Actions" />
                </th>
              )}
            </tr>
          </thead>
          <tbody>{establishmentsNodes}</tbody>
        </table>

        {!this.props.limit && this.paginator.render()}

        {this.state.modal}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    clients: state.clients,
    establishments: state.establishments,
    establishmentTypes: state.establishmentTypes,
    paymentMethods: state.paymentMethods,
    paymentDeadlines: state.paymentDeadlines,
    establishmentsSettings: state.establishmentsSettings,
    mercurials: state.mercurials,
    user: state.user,
    company: state.company,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetEstablishmentTypes: () => dispatch(getEstablishmentTypes()),
    onGetPaymentMethods: () => dispatch(getPaymentMethods()),
    onGetPaymentDeadlines: () => dispatch(getPaymentDeadlines()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(Establishments));
