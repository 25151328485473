import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import OrderRowAdmin from "./OrderRowAdmin";
import { getListProductsAdmin } from "../../../actions/products/products";
import ModalManager from "../../sub/modals/ModalManager";
import SplitModal from "./SplitOrderModalAdmin";
import OrderStatus from "../../../enums/OrderStatus";
import Paginator from "../../sub/Paginator";
import DateUtil from "../../../util/DateUtil";
import Util from "../../../util/Util";
import CustomLabel from "../../sub/CustomLabel";
import TableToolbar from "../../sub/bootstrap/TableToolbar";
import MenuButton from "../../sub/bootstrap/MenuButton";
import { mustGetEtatDossier } from "../../../actions/apiMust/apiMust";
import Roles from "../../../enums/Roles";
import { CrmProviders } from "../../../enums/crmProviders";
import { lomacoGetEtatDocument } from "../../../actions/apiLomaco/apiLomaco";

class OrdersAdmin extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: null,
      establishmentFilter: "",
      referenceFilter: "",
      dateStartFilter: "",
      dateEndFilter: "",
      statusFilter: "",
      crm_sync_order_status: this.props.company.crm?.options?.must_allow_sync_order_status || false,
    };

    this.paginator = new Paginator(this);
  }

  openSplitModal(order) {
    this.setState({
      modal: (
        <SplitModal
          isOpen={true}
          order={order}
          closeConfModal={() => this.closeModal()}
        />
      ),
    });
  }

  openErrorModal(title, content, successCallback) {
    this.setState({
      modal: (
        <ModalManager
          showModal={true}
          title={title}
          content={content}
          successCallback={successCallback}
          closeModal={(e) => this.closeModal()}
          modalType="error"

        />
      ),
    });
  }

  openConfModal(title, content, successCallback) {
    this.setState({
      modal: (
        <ModalManager
          showModal={true}
          title={title}
          content={content}
          successCallback={successCallback}
          closeModal={() => this.closeModal()}
          modalType="confirmation"
        />
      ),
    });
  }

  openSuccessModal(title, content) {
    this.setState({
      modal: (
        <ModalManager
          showModal={true}
          title={title}
          content={content}
          closeModal={() => this.closeModal()}
          modalType="success"
        />
      ),
    });
  }

  openLoadingModal(title, content, event) {
    this.setState({
      modalnext: (
        <ModalManager
          showModal={true}
          title={title}
          content={content}
          event={event}
          closeModal={() => this.closeModalNext()}
          modalType="loading"
        />
      ),
    });
  }

  closeModal() {
    this.setState({ modal: null });
  }

  closeModalNext() {
    this.setState({ modalnext: null });
  }

  getAdmin(establishmentId) {
    for (var collaborator of this.props.collaborators) {
      if (collaborator.establishments) {
        for (var establishment of collaborator.establishments) {
          if (establishment.establishment_id === establishmentId)
            return collaborator;
        }
      }
    }
  }

  getEstablisment(establishmentId) {
    for (var establishment of this.props.establishments) {
      if (establishment._id === establishmentId) return establishment;
    }
  }

  setStartDateFilter = (date) => {
    this.setState({
      dateStartFilter: new Date(
        new Date(date).getFullYear(),
        new Date(date).getMonth(),
        new Date(date).getDate(),
        0,
        0,
        0,
        0
      ),
    });
  };

  setEndDateFilter = (date) => {
    this.setState({
      dateEndFilter: new Date(
        new Date(date).getFullYear(),
        new Date(date).getMonth(),
        new Date(date).getDate(),
        23,
        59,
        59,
        999
      ),
    });
  };

  areResultsFiltered = () => {
    return (
      !Util.emptyString(this.state.establishmentFilter) ||
      !Util.emptyString(this.state.referenceFilter) ||
      !Util.emptyString(this.state.dateStartFilter) ||
      !Util.emptyString(this.state.dateEndFilter) ||
      !Util.emptyString(this.state.statusFilter)
    );
  };

  resetSearchFields() {
    this.setState({
      establishmentFilter: "",
      referenceFilter: "",
      dateStartFilter: "",
      dateEndFilter: "",
      statusFilter: "",
    });
  }

  getProductsByOrder(order, products) {
    var currentOrderProducts = [];
    for (let op of order.products) {
      for (let p of products) {
        if (op.id_product === p._id) {
          currentOrderProducts.push(p);
        }
      }
    }
    return currentOrderProducts;
  }

  syncDossierModal() {
    if(this.props.company.crm.software === CrmProviders.MUST.software){
      this.openConfModal(
          <FormattedMessage id="API.CRM.Synchronize.With" values={{ crmSoftware: "MustG5" }} />,
          <FormattedMessage id="API.CRM.Synchronize.Order.Status.With.Content" values={{ crmSoftware: "MustG5" }} />,
          () => {
              this.openLoadingModal(
                  <FormattedMessage id="Sync.In.Progress" />,
                  <FormattedMessage id="Sync.In.Progress.Warning" />,
                  () => {
                      this.props.onGetEtatDossier(
                          this.props.company._id,
                          () => {
                              this.closeModalNext();
                              this.openSuccessModal(
                                  <FormattedMessage id="API.CRM.Synchronize.Order.Status.With.Success.Title" />,
                                  <FormattedMessage id="API.CRM.Synchronize.Order.Status.With.Success.Content" values={{ crmSoftware: "MustG5" }} />)
                          },
                          (r) => {
                              this.closeModalNext();
                              this.openErrorModal(
                                <FormattedMessage id="API.CRM.Sync.Error" />,
                                <div>
                                  <p className="alert alert-danger text-justify">
                                    <FormattedMessage id="API.CRM.Synchronize.Order.Status.With.Error.Content" />
                                  </p>
                                  <div className="alert alert-light text-break">
                                    <p>
                                      <FormattedMessage id="Error.Detail" />:
                                    </p>
                                    <code>
                                      {JSON.stringify(
                                        r ? (
                                          r.message
                                        ) : (
                                          <FormattedMessage id="Message.Undefined" />
                                        )
                                      )}
                                    </code>
                                  </div>
                                </div>,
                                () => {}
                              );
                          }
                      )
                  }
              )
          }
      )
  } else if(this.props.company.crm.software === CrmProviders.LOMACO.software){
      this.openConfModal(
          <FormattedMessage id="API.CRM.Synchronize.With" values={{ crmSoftware: "Lomaco" }} />,
          <FormattedMessage id="API.CRM.Synchronize.Order.Status.With.Content" values={{ crmSoftware: "Lomaco" }} />,
          () => {
              this.openLoadingModal(
                  <FormattedMessage id="Sync.In.Progress" />,
                  <FormattedMessage id="Sync.In.Progress.Warning" />,
                  () => {
                      this.props.onGetEtatDocument(
                          this.props.company._id,
                          () => {
                              this.closeModalNext();
                              this.openSuccessModal(
                                  <FormattedMessage id="API.CRM.Synchronize.Order.Status.With.Success.Title" />,
                                  <FormattedMessage id="API.CRM.Synchronize.Order.Status.With.Success.Content" values={{ crmSoftware: "Lomaco" }} />)
                          },
                          () => {
                              this.closeModalNext();
                              this.openErrorModal(
                                  <FormattedMessage id="API.CRM.Sync.Error" />,
                                  <FormattedMessage id="API.CRM.Synchronize.Order.Status.With.Error.Content" />,
                                  () => { }
                              )
                          }
                      )
                  }
              )
          }
      )
  }

  }

  render() {
    if (!this.props.orders || this.props.orders.length === 0) {
      return (
        <React.Fragment>
          <div className="alert alert-secondary" role="alert">
            <FormattedMessage id="Admin.Empty.Orders" />
          </div>
        </React.Fragment>
      );
    }

    this.paginator.init();

    let disableFormInput = this.paginator.paginationIndex !== 1 ? true : false;

    // https://stackoverflow.com/questions/37308719/react-component-wait-for-required-props-to-render
    // https://zaiste.net/posts/javascript-destructuring-assignment-default-values/
    const { enabled: crmEnabled = false, software: crmSoftware = null } = this
      .props.company.crm
      ? this.props.company.crm
      : {};
      const { must_allow_sync_order_status: mustAllowSyncOrderStatus = false,lomaco_allow_sync_order_status: lomacoAllowSyncOrderStatus = false } = this.props.company.crm?.options ? this.props.company.crm?.options : {};
    var i = 0;
    let ordersNode = this.props.orders.map((order) => {
      if (this.state.clientFilter && this.state.clientFilter !== "") {
        if (order.id_client !== this.state.clientFilter) return null;
      }

      if (
        this.state.establishmentFilter &&
        this.state.establishmentFilter !== ""
      ) {
        // console.log(this.state.establishmentFilter);
        if (order.establishment_id !== this.state.establishmentFilter)
          return null;
      }

      if (this.state.referenceFilter && this.state.referenceFilter !== "") {
        if (order.ref.indexOf(this.state.referenceFilter) === -1) return null;
      }

      if (this.state.dateStartFilter && this.state.dateStartFilter !== "") {
        if (
          new Date(order.date) < new Date(this.state.dateStartFilter)
        )
          return null;
      }

      if (this.state.dateEndFilter && this.state.dateEndFilter !== "") {
        if (new Date(order.date) >= new Date(this.state.dateEndFilter))
          return null;
      }

      if (this.state.statusFilter && this.state.statusFilter !== "") {
        if (order.status !== Number(this.state.statusFilter)) return null;
      }

      /**
       * Orders "to be validated" are not shown
       */
      if (order.status === OrderStatus.TBV) return null;

      if (this.props.limit && ++i > this.props.limit) return null;

      if (!this.paginator.keep()) return null;

      return (
        <OrderRowAdmin
          key={order._id}
          order={order}
          products={order.products}
          productsForCrm={this.getProductsByOrder(order, this.props.products)}
          mercurials={this.props.mercurials}
          admin={this.getAdmin(order.establishment_id)}
          establishment={this.getEstablisment(order.establishment_id)}
          clientName={order.client_name}
          openConfModal={(title, content, successCallback) =>
            this.openConfModal(title, content, successCallback)
          }
          openErrorModal={(title, content, successCallback) =>
            this.openErrorModal(title, content, successCallback)
          }
          openSuccessModal={(title, content, successCallback) =>
            this.openSuccessModal(title, content, successCallback)
          }
          openSplitModal={(order) => this.openSplitModal(order)}
          limit={this.props.limit && true}
        />
      );
    });

    /*
        var clientsNode = this.props.clients.map(client => {
            return <option key={client._id} value={client._id}>{client.first_name} {client.name}</option>
        });
        */

    var establishmentsNode = this.props.establishments.map((establishment) => {
      return (
        <option key={establishment._id} value={establishment._id}>
          {establishment.name}
        </option>
      );
    });

    /**
     * Do not display the "to be validated" option in search fiter select (useless for admin)
     */
    /*
        var statusNode = Object.values(OrderStatus).map(option => {
            return <option key={option} value={option}>{this.props.intl.formatMessage({ id: "Order.Status." + option })}</option>
        });
        */
    let statusToBeDisplayed = Object.assign({}, OrderStatus);
    /**
     * Do not display the "to be validated" option in search fiter select (useless)
     */
    delete statusToBeDisplayed.TBV;
    var statusNode = Object.values(statusToBeDisplayed).map((option) => {
      return (
        <option key={option} value={option}>
          {this.props.intl.formatMessage({ id: "Order.Status." + option })}
        </option>
      );
    });

    if (!this.props.establishments.length) {
      return null;
    }

    if (!this.props.establishments.length) {
      return null;
    }

    return (
      <React.Fragment>
        {!this.props.limit && (
          <TableToolbar>
            <CustomLabel
              label={this.props.intl.formatMessage({ id: "Client" })}
              htmlFor="search_client"
              labelClassName="my-1 mr-2"
            />
            <select
              id="search_client"
              className="form-control mr-sm-3"
              onChange={(e) => {
                this.setState({ establishmentFilter: e.target.value });
              }}
              disabled={disableFormInput}
              value={this.state.establishmentFilter}
            >
              <option value="">
                {this.props.intl.formatMessage({ id: "All" })}
              </option>
              {establishmentsNode}
            </select>
            <CustomLabel
              label={this.props.intl.formatMessage({ id: "Reference" })}
              htmlFor="search_reference"
              labelClassName="my-1 mr-2"
            />
            <input
              id="search_reference"
              className="form-control col-1 mr-sm-3"
              onChange={(e) => {
                this.setState({ referenceFilter: e.target.value });
              }}
              disabled={disableFormInput}
              value={this.state.referenceFilter}
            />
            <CustomLabel
              label={this.props.intl.formatMessage({ id: "Date" })}
              htmlFor="search_date"
              labelClassName="my-1 mr-2"
            />
            <input
              id="search_date"
              className="form-control mr-sm-3"
              type="date"
              onChange={(e) => {
                this.setStartDateFilter(e.target.value);
              }}
              disabled={disableFormInput}
              value={DateUtil.toyyyyMMdd(this.state.dateStartFilter)}
            />
            <CustomLabel
              label={this.props.intl.formatMessage({ id: "Status" })}
              htmlFor="search_status"
              labelClassName="my-1 mr-2"
            />
            <select
              id="search_status"
              className="form-control mr-sm-3"
              onChange={(e) => {
                this.setState({ statusFilter: e.target.value });
              }}
              disabled={disableFormInput}
              value={this.state.statusFilter}
            >
              <option value="">
                {this.props.intl.formatMessage({ id: "All" })}
              </option>
              {statusNode}
            </select>
            <MenuButton
              onClick={() => this.resetSearchFields()}
              hover={
                this.areResultsFiltered() &&
                !disableFormInput && <FormattedMessage id="Remove.Filter" />
              }
              variant={
                this.areResultsFiltered() ? "warning" : "outline-secondary"
              }
              icon="filter"
              disabled={!this.areResultsFiltered() || disableFormInput}
            />
          </TableToolbar>
        )}

        <table className="table tablee4coll">
          <thead>
            <tr className="d-flex align-items-center">
              <th scope="col" className="col col-2">
                <FormattedMessage id="Establishment" />
              </th>
              <th scope="col" className="col">
                <FormattedMessage id="Buyer" />
              </th>
              <th scope="col" className="col">
                <FormattedMessage id="Reference" />
              </th>
              <th scope="col" className="col">
                <FormattedMessage id="Date" />
              </th>
              <th scope="col" className="col">
                <FormattedMessage id="Expected.Delivery.Date" />
              </th>
              <th scope="col" className="col">
                <FormattedMessage id="Total.Excl.Tax" />
              </th>
              <th scope="col" className="col col-2">
                <FormattedMessage id="Order.Status" />
                {Roles.ADMIN &&
                  crmEnabled &&
                  (mustAllowSyncOrderStatus || lomacoAllowSyncOrderStatus) && this.state.crm_sync_order_status && (
                    <MenuButton
                      variant="info"
                      icon="arrow-right-arrow-left"
                      size="sm"
                      className="ml-1"
                      placement="bottom"
                      onClick={() => this.syncDossierModal()}
                      hover={
                        <FormattedMessage
                          id="API.CRM.Synchronize.With"
                          values={{ crmSoftware: "MustG5" }}
                        />
                      }
                    >
                      Sync.
                    </MenuButton>
                  )}
              </th>
              {crmEnabled && (
                <th scope="col" className="col col-1 text-center">
                  <FormattedMessage
                    id="API.CRM.Sync.Short"
                    values={{ crmSoftware: crmSoftware }}
                  />
                </th>
              )}
              {!this.props.limit && (
                <th scope="col" className="col text-center">
                  <FormattedMessage id="Actions" />
                </th>
              )}
            </tr>
          </thead>
          <tbody>{ordersNode}</tbody>
        </table>

        {!this.props.limit && this.paginator.render()}

        {this.state.modal}
        {this.state.modalnext}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    lang: state.i18n.lang,
    orders: state.orders,
    clients: state.clients,
    collaborators: state.collaborators,
    products: state.products,
    establishments: state.establishments,
    establishmentsSettings: state.establishmentsSettings,
    mercurials: state.mercurials,
    company: state.company,
    ordersSettingsAdmin: state.ordersSettingsAdmin,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetProductsAdmin: (data) => dispatch(getListProductsAdmin(data)),
    onGetEtatDossier: (data, successCallback, failureCallback) =>
      dispatch(mustGetEtatDossier(data, successCallback, failureCallback)),
    onGetEtatDocument:(data, successCallback, failureCallback) => dispatch(lomacoGetEtatDocument(data, successCallback, failureCallback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(OrdersAdmin));
