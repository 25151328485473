import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import Util from "../../util/Util";
import { addClient, updateClient } from "../../actions/clients/clients";
import {
  checkIfUsernameExists
} from "../../actions/user/user";
import CustomLabel from "../sub/CustomLabel";
import { Button, Modal } from "react-bootstrap";

class UserModal extends React.Component {
  constructor(props) {
    super(props);

    if (this.props.user) {
      var user = this.props.user;
      this.state = {
        username: user.username,
        name: user.name,
        first_name: user.first_name,
        function: user.function,
        email: user.email,
        phone: user.phone,
        validator: user.validator,

        // Errors
        usernameError: null,
        nameError: null,
        first_nameError: null,
        emailError: null,

        disabled: false,
      };
    } else {
      this.state = {
        username: "",
        name: "",
        first_name: "",
        function: "",
        email: "",
        phone: "",
        validator: true,
        notifyByEmail: false,

        // Errors
        usernameError: null,
        nameError: null,
        first_nameError: null,
        emailError: null,

        disabled: false,
      };
    }
  }

  scanErrors(noErrorCallback) {
    // Check for variables emptyness
    var isUsernameEmpty = Util.emptyString(this.state.username);
    var isNameEmpty = Util.emptyString(this.state.name);
    var isFirstNameEmpty = Util.emptyString(this.state.first_name);
    var isEmailEmpty = Util.emptyString(this.state.email);

    // Empty fields errors
    if (isUsernameEmpty) {
      this.setState({
        usernameError: <FormattedMessage id="Empty.Username" />,
      });
    }
    if (isNameEmpty) {
      this.setState({ nameError: <FormattedMessage id="Empty.Name" /> });
    }
    if (isFirstNameEmpty) {
      this.setState({
        first_nameError: <FormattedMessage id="Empty.FirstName" />,
      });
    }
    if (isEmailEmpty) {
      this.setState({ emailError: <FormattedMessage id="Empty.Email" /> });
    }

    // Invalid email error
    if (!Util.isEmail(this.state.email)) {
      this.setState({ emailError: <FormattedMessage id="Invalid.Email" /> });
    }

    // We are modifying a user. No need to check for email & username unicity
    if (this.props.user) {
      if (
        !isUsernameEmpty &&
        !isNameEmpty &&
        !isFirstNameEmpty &&
        !isFirstNameEmpty
      )
        return noErrorCallback();
      else return;
    }

    if (isUsernameEmpty || isEmailEmpty) return;

    // Make sure username is unique
    this.props.onCheckIfUsernameExists(
      this.state.username,
      () => {
        this.setState({
          usernameError: <FormattedMessage id="Username.Already.Exists" />,
        });
      },
      () => noErrorCallback()
    );
  }

  onComplete() {
    var createUser = () => {
      var user = {
        username: this.state.username,
        name: this.state.name,
        first_name: this.state.first_name,
        function: this.state.function,
        email: this.state.email,
        validator: this.state.validator,
        phone: this.state.phone,
        notifyByEmail: this.state.notifyByEmail,
        establishment_id: this.props.establishment._id,
      };

      this.setState({ disabled: true });

      this.props.onAddClient(user, () => this.props.close());
    };

    // Validate fields & send to BE
    this.scanErrors(createUser);
  }

  update(field) {
    if (!this.props.user || this.state.emailError || this.state.usernameError)
      return;

    if (field === "username" && this.props.user.username === this.state[field])
      return;

    if (field === "email" && this.props.user.email === this.state[field])
      return;

    var updateValue = () => {
      var data = {
        clientId: this.props.user._id,
        updatedField: field,
        updatedValue: this.state[field],
      };

      this.props.onUpdateClient(data);
    };

    // Validate fields & send to BE
    this.scanErrors(updateValue);
  }

  onFieldChange(field, value) {
    if (field === "notifyByEmail") {
      this.setState({ notifyByEmail: value });
      this.forceUpdate(() => this.setState({ notifyByEmail: value }));
    }

    if (field === "validator")
      this.setState({ validator: !this.state.validator });
    else this.setState({ [field]: value });

    // Empty fields errors
    if (field === "name" && !Util.emptyString(value))
      this.setState({ nameError: null });
    if (field === "first_name" && !Util.emptyString(value))
      this.setState({ first_nameError: null });

    // Check if username is not empty and not already taken
    if (field === "username") {
      if (Util.emptyString(value)) return;

      // Force this value to be in lowercase
      // value = value.toLowerCase();

      this.setState({ [field]: value });

      // Test username syntax
      var checkUsername = Util.isValidUserName(value, 3, 20);
      if (checkUsername instanceof Object === true) {
        this.setState({
          usernameError: (
            <FormattedMessage
              id="Invalid.Username"
              values={{
                minLength: checkUsername.minLength,
                maxLength: checkUsername.maxLength,
              }}
            />
          ),
        });
        return;
      } else {
        this.setState({ usernameError: null });
      }

      if (!this.props.user || this.props.user.username !== value) {
        this.props.onCheckIfUsernameExists(
          value,
          () => {
            this.setState({
              usernameError: (
                <FormattedMessage
                  id="Username.Already.Exists"
                  values={{ username: value }}
                />
              ),
            });
          },
          () => {
            this.setState({ usernameError: null });
          }
        );
      }
    }

    // Check if email is not empty and not already taken
    if (field === "email" && !Util.emptyString(value)) {
      this.setState({ emailError: null });

      if (!Util.isEmail(value)) {
        this.setState({ emailError: <FormattedMessage id="Invalid.Email" /> });
        return;
      }
    }
  }

  disabled() {
    return (
      Util.emptyString(this.state.username) ||
      Util.emptyString(this.state.email) ||
      Util.emptyString(this.state.name) ||
      Util.emptyString(this.state.first_name) ||
      this.state.usernameError ||
      this.state.emailError ||
      this.state.nameError ||
      this.state.first_nameError ||
      this.state.disabled
    );
  }

  render() {
    const { user, close, intl, connectedUser, company } = this.props;
    let title = <FormattedMessage id={user ? "Modify.User" : "Add.User"} />;
    return (
      <Modal show={true} onHide={() => close()} backdrop={"static"} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="form-group row">
            <CustomLabel
              label={intl.formatMessage({ id: "Username" })}
              htmlFor="user-username"
              labelClassName="col-12 col-md-5"
              required
            />
            <div className="col-12 col-md-7">
              <input
                disabled={connectedUser.role === 2}
                type="text"
                className="form-control col-12 col-md-7 d-inline"
                id="user-username"
                autoComplete="off"
                value={this.state.username}
                onChange={(e) =>
                  this.onFieldChange("username", e.target.value.toLowerCase())
                }
                onBlur={(e) => this.update("username")}
              />
              <span className="col-12 col-md-3">-{company.url}</span>
              <div className="text-danger">
                <small>{this.state.usernameError}</small>
              </div>
            </div>
          </div>

          <div className="form-group row">
            <CustomLabel
              label={intl.formatMessage({ id: "Name" })}
              htmlFor="user-name"
              labelClassName="col-12 col-md-5"
              required
            />
            <div className="col-12 col-md-7">
              <input
                disabled={connectedUser.role === 2}
                type="text"
                className="form-control"
                id="user-name"
                autoComplete="off"
                value={this.state.name}
                onChange={(e) =>
                  this.onFieldChange("name", e.target.value.toUpperCase())
                }
                onBlur={(e) => this.update("name")}
              />
              <div className="text-danger">
                <small>{this.state.nameError}</small>
              </div>
            </div>
          </div>

          <div className="form-group row">
            <CustomLabel
              label={intl.formatMessage({ id: "First.Name" })}
              htmlFor="user-first-name"
              labelClassName="col-12 col-md-5"
              required
            />
            <div className="col-12 col-md-7">
              <input
                disabled={connectedUser.role === 2}
                type="text"
                className="form-control"
                id="user-first_name"
                autoComplete="off"
                value={this.state.first_name}
                onChange={(e) =>
                  this.onFieldChange("first_name", e.target.value)
                }
                onBlur={(e) => this.update("first_name")}
              />
              <div className="text-danger">
                <small>{this.state.first_nameError}</small>
              </div>
            </div>
          </div>

          <div className="form-group row">
            <CustomLabel
              label={intl.formatMessage({ id: "Function" })}
              htmlFor="user-function"
              labelClassName="col-12 col-md-5"
            />
            <div className="col-12 col-md-7">
              <input
                disabled={connectedUser.role === 2}
                type="text"
                className="form-control"
                id="user-function"
                autoComplete="off"
                value={this.state.function}
                onChange={(e) =>
                  this.onFieldChange("function", e.target.value)
                }
                onBlur={(e) => this.update("function")}
              />
            </div>
          </div>

          <div className="form-group row">
            <CustomLabel
              label={intl.formatMessage({ id: "Email" })}
              htmlFor="user-email"
              labelClassName="col-12 col-md-5"
              required
            />
            <div className="col-12 col-md-7">
              <input
                disabled={connectedUser.role === 2}
                type="email"
                className="form-control"
                id="user-email"
                autoComplete="off"
                value={this.state.email}
                onChange={(e) =>
                  this.onFieldChange("email", e.target.value.toLowerCase())
                }
                onBlur={(e) => this.update("email")}
              />
              <div className="text-danger">
                <small>{this.state.emailError}</small>
              </div>
            </div>
          </div>

          <div className="form-group row">
            <CustomLabel
              label={intl.formatMessage({ id: "Phone" })}
              htmlFor="user-phone"
              labelClassName="col-12 col-md-5"
            />
            <div className="col-12 col-md-7">
              <input
                disabled={connectedUser.role === 2}
                type="text"
                className="form-control"
                id="user-phone"
                autoComplete="off"
                value={this.state.phone}
                onChange={(e) => this.onFieldChange("phone", e.target.value)}
                onBlur={(e) => this.update("phone")}
              />
            </div>
          </div>

          <div className="form-group row">
            <CustomLabel
              label={intl.formatMessage({ id: "Validator" })}
              htmlFor="validator-switch"
              labelClassName="col-12 col-md-5"
            />
            <div className="col-12 col-md-7">
              <div className="custom-switch switch-primary">
                <input
                  disabled={connectedUser.role === 2}
                  onChange={(e) =>
                    this.onFieldChange("validator", e.target.value)
                  }
                  type="checkbox"
                  id="validator-switch"
                  className="custom-control-input switch-bg-blue"
                  checked={this.state.validator}
                  onBlur={(e) => this.update("validator")}
                />
                <CustomLabel
                  htmlFor="validator-switch"
                  labelClassName="custom-control-label"
                />
              </div>
            </div>
          </div>

          {!this.isUpdate && (
            <div className="form-group row">
              <CustomLabel
                label={intl.formatMessage({ id: "Send.Mail.Notify.Created.Account" })}
                htmlFor="notifyByEmail"
                labelClassName="col-12 col-md-5"
              />
              <div className="col-12 col-md-7">
                <div className="custom-switch switch-primary">
                  <input
                    type={"checkbox"}
                    id="notifyByEmail"
                    className={"custom-control-input switch-bg-blue"}
                    onChange={(e) => {
                      this.onFieldChange("notifyByEmail", e.target.checked);
                    }}
                    checked={this.state.notifyByEmail}
                  />
                  <CustomLabel
                    htmlFor="notifyByEmail"
                    labelClassName="custom-control-label"
                  />
                </div>
              </div>
            </div>
          )}
        </Modal.Body>

        {!user && (
          <Modal.Footer>
            <Button variant="secondary" onClick={() => close()}>
              <FormattedMessage id="Cancel" />
            </Button>
            <Button
              variant="info"
              onClick={() => this.onComplete()}
              disabled={this.disabled()}
            >
              <FormattedMessage id="Add" />
            </Button>
          </Modal.Footer>
        )}
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    connectedUser: state.user,
    company: state.company,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAddClient: (data, successCallback) =>
      dispatch(addClient(data, successCallback)),
    onCheckIfUsernameExists: (username, existsCallback, noExistsCallback) =>
      dispatch(
        checkIfUsernameExists(username, existsCallback, noExistsCallback)
      ),
    onUpdateClient: (data) => dispatch(updateClient(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(UserModal));
