import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import EqMateriovigilance from "../../../../enums/EqMateriovigilance";
import MateriovigilanceModalAdmin from "./MateriovigilanceModalAdmin";
import MateriovigilanceAdminRow from "./MateriovigilanceAdminRow.js";
import CustomLabel from "../../../sub/CustomLabel";
import DateUtil from "../../../../util/DateUtil";
import Util from "../../../../util/Util";
import Paginator from "../../../sub/Paginator";
import TableToolbar from "../../../sub/bootstrap/TableToolbar";
import MenuButton from "../../../sub/bootstrap/MenuButton";
import ModalManager from "../../../sub/modals/ModalManager";

class Materiovigilance extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: null,

      //***** FILTER STATE ******/
      brandOrModelFilter: "",
      creationDateFilter: "",
      notificationDateFilter: "",
      materioviglanceFilter: 10,
    };
    this.paginator = new Paginator(this);
  }

  openMaterioviglanceModal() {
    this.setState({
      modal: (
        <MateriovigilanceModalAdmin
          labelClassName="col col-12 col"
          required={true}
          isOpen={true}
          closeModal={() => this.closeModal()}
        />
      ),
    });
  }

  closeModal() {
    this.setState({ modal: null });
  }

  setCreationDateFilter = (date) => {
    this.setState({
      creationDateFilter: new Date(
        new Date(date).getFullYear(),
        new Date(date).getMonth(),
        new Date(date).getDate(),
        0,
        0,
        0,
        0
      ),
    });
  };

  setNotificationDateFilter = (date) => {
    this.setState({
      notificationDateFilter: new Date(
        new Date(date).getFullYear(),
        new Date(date).getMonth(),
        new Date(date).getDate(),
        0,
        0,
        0,
        0
      ),
    });
  };

  areResultsFiltered = () => {
    if (
      !Util.emptyString(this.state.brandOrModelFilter) ||
      !Util.emptyString(this.state.creationDateFilter) ||
      !Util.emptyString(this.state.notificationDateFilter) ||
      this.state.materioviglanceFilter !== 10
    ) {
      return true;
    } else {
      return false;
    }
  };

  resetSearchFields() {
    this.setState({
      brandOrModelFilter: "",
      creationDateFilter: "",
      notificationDateFilter: "",
      materioviglanceFilter: 10,
    });
  }

  openConfModal(title, content, successCallback) {
    this.setState({
      modal: (
        <ModalManager
          showModal={true}
          title={title}
          content={content}
          buttonRed={true}
          successCallback={successCallback}
          closeModal={() => this.closeModal()}
          modalType="confirmation"
        />
      ),
    });
  }

  openEditModal(materiovigilance) {
    let brandID = this.props.models.filter(
      (model) => model._id === materiovigilance.model_id
    )[0].brand_id;

    this.setState({
      modal: (
        <MateriovigilanceModalAdmin
          isOpen={true}
          labelClassName="col col-12 col"
          required={false}
          closeModal={() => this.closeModal()}
          materiovigilanceLoaded={materiovigilance}
          brandId={brandID}
        />
      ),
    });
  }

  onChange(field, value) {
    this.setState({ [field]: value });
  }

  render() {
    if (
      !this.props.materiovigilance ||
      this.props.materiovigilance.length === 0
    ) {
      return (
        <React.Fragment>
          <TableToolbar>
            <div className="alert alert-info w-100">
              <FormattedMessage id="Admin.Empty.Materiovigilance" />
            </div>
            <button
              className="btn btn-info ml-auto"
              onClick={(e) => this.openMaterioviglanceModal()}
            >
              <FormattedMessage id="Declare.Materiovigilance" />
            </button>
          </TableToolbar>
          {this.state.modal}
        </React.Fragment>
      );
    }

    this.paginator.init();

    //*************** content Filterd of table ********/

    let disableFormInput = this.paginator.paginationIndex !== 1 ? true : false;

    var filteredContentMateriovigilance = this.props.materiovigilance.map(
      (eachMateriovigilance) => {
        // var modelName = this.props.models.filter(
        //   (model) => model._id === eachMateriovigilance.model_id
        // )[0].name;

        // var brandId = this.props.models.filter(
        //   (model) => model._id === eachMateriovigilance.model_id
        // )[0].brand_id;
        // var brandName = this.props.brands.filter(
        //   (brand) => brand._id === brandId
        // )[0].name;

        var matchingModel = this.props.models.find(
          (model) => model._id === eachMateriovigilance.model_id
        );
        var modelName = matchingModel ? matchingModel.name : "Unknown";

        var brandId = matchingModel ? matchingModel.brand_id : null; // Handle the case when no matching model is found
        var brandName = brandId
          ? this.props.brands.find((brand) => brand._id === brandId)?.name
          : "Unknown";

        //****** Brand & model filter ******/
        if (
          this.state.brandOrModelFilter !== "" &&
          modelName
            .toLowerCase()
            .includes(this.state.brandOrModelFilter.toLowerCase()) === false &&
          brandName
            .toLowerCase()
            .includes(this.state.brandOrModelFilter.toLowerCase()) === false
        ) {
          return null;
        }
        //****** Date filter ******/

        if (this.state.creationDateFilter !== "") {
          var CreationDate = DateUtil.toDate(eachMateriovigilance.createdAt);
          var FilterCreationDate = DateUtil.toDate(
            this.state.creationDateFilter
          );
          if (CreationDate < FilterCreationDate) return null;
        }

        if (this.state.notificationDateFilter !== "") {
          var NotificationDate = DateUtil.toDate(eachMateriovigilance.date);
          var FilterNotificationDate = DateUtil.toDate(
            this.state.notificationDateFilter
          );
          if (NotificationDate !== FilterNotificationDate) return null;
        }

        //****** Materio Status filter ******/
        if (
          this.state.materioviglanceFilter !== 10 &&
          this.state.materioviglanceFilter !==
            eachMateriovigilance.materiovigilance
        ) {
          return null;
        }
        if (!this.paginator.keep()) return null;

        return (
          <MateriovigilanceAdminRow
            key={eachMateriovigilance._id}
            materiovigilance={eachMateriovigilance}
            type={eachMateriovigilance}
            brand={brandName}
            model={modelName}
            openConfModal={(title, content, successCallback) =>
              this.openConfModal(title, content, successCallback)
            }
            openEditModal={(eachMateriovigilance) =>
              this.openEditModal(eachMateriovigilance)
            }
          />
        );
      }
    );

    //*************** List of materioviglance type ********/

    var eqMateriovigilanceNode = Object.values(EqMateriovigilance).map(
      (option) => {
        if (option !== 0) {
          return (
            <option key={option} value={option}>
              {this.props.intl.formatMessage({
                id: "EqMateriovigilance." + option,
              })}
            </option>
          );
        } else {
          return null;
        }
      }
    );

    return (
      <React.Fragment>
        {this.props.materiovigilance && (
          <TableToolbar>
            <CustomLabel
              label={this.props.intl.formatMessage({ id: "Dm" })}
              htmlFor="search_materiovigilance"
              labelClassName="my-1 mr-2"
            />
            <input
              id="search_materiovigilance"
              className="form-control mr-sm-3"
              // type="search"
              onChange={(e) =>
                this.onChange("brandOrModelFilter", e.target.value)
              }
              placeholder={this.props.intl.formatMessage({
                id: "Brand.Model",
              })}
              disabled={disableFormInput}
              value={this.state.brandOrModelFilter}
            />
            <CustomLabel
              label={this.props.intl.formatMessage({ id: "Status" })}
              htmlFor="materiovigilance-status-filter"
              labelClassName="my-1 mr-2"
            />
            <select
              id="materiovigilance-status-filter"
              className="form-control mr-sm-3"
              value={this.state.materioviglanceFilter}
              onChange={(e) =>
                this.setState({
                  materioviglanceFilter: parseInt(e.target.value),
                })
              }
              disabled={disableFormInput}
            >
              <option key={10} value={10}>
                {this.props.intl.formatMessage({ id: "All.Fem" })}
              </option>
              {eqMateriovigilanceNode}
            </select>
            <CustomLabel
              label={this.props.intl.formatMessage({
                id: "Creation.Date",
              })}
              htmlFor="search_start_date"
              labelClassName="my-1 mr-2"
            />
            <input
              id="search_start_date"
              className="form-control mr-sm-3"
              type="date"
              onChange={(e) => {
                this.setCreationDateFilter(e.target.value);
              }}
              disabled={disableFormInput}
              value={DateUtil.toyyyyMMdd(this.state.creationDateFilter)}
            />
            <CustomLabel
              label={this.props.intl.formatMessage({
                id: "Date.Notification",
              })}
              htmlFor="search_end_date"
              labelClassName="my-1 mr-2"
            />
            <input
              id="search_end_date"
              className="form-control mr-sm-3"
              type="date"
              onChange={(e) => {
                this.setNotificationDateFilter(e.target.value);
              }}
              disabled={disableFormInput}
              value={DateUtil.toyyyyMMdd(this.state.notificationDateFilter)}
            />
            <MenuButton
              onClick={() => this.resetSearchFields()}
              hover={
                this.areResultsFiltered() &&
                !disableFormInput && <FormattedMessage id="Remove.Filter" />
              }
              variant={
                this.areResultsFiltered() ? "warning" : "outline-secondary"
              }
              icon="filter"
              disabled={!this.areResultsFiltered() || disableFormInput}
            />
            <button
              className="btn btn-info ml-auto"
              onClick={(e) => this.openMaterioviglanceModal()}
            >
              <FormattedMessage id="Declare.Materiovigilance" />
            </button>
          </TableToolbar>
        )}

        <table className="table tablee4coll">
          <thead>
            <tr className="d-flex">
              <th scope="col" className="col">
                <FormattedMessage id="Model" />
              </th>
              <th scope="col" className="col col-3">
                <FormattedMessage id="Materiovigilance.Status" />
              </th>
              <th scope="col" className="col col-2">
                <FormattedMessage id="Creation.Date" />
              </th>
              <th scope="col" className="col col-2">
                <FormattedMessage id="Date.Notification" />
              </th>
              <th scope="col" className="text-center col col-1">
                <FormattedMessage id="Actions" />
              </th>
            </tr>
          </thead>
          <tbody>{filteredContentMateriovigilance}</tbody>
        </table>

        {/* {filteredContentMateriovigilance} */}
        {this.paginator.render()}

        {this.state.modal}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    materiovigilance: state.materiovigilance,
    models: state.models,
    equipments: state.equipments,
    brands: state.brands,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(Materiovigilance));
